import BTNAddToBento from "./BTNAddToBento";

const FilterFoodListByCategoriesAndAllergens = ({
  foods,
  selectedCategories,
  categories,
  selectedAllergens,
  allergens,
  supplements,
}) => {
  // random effect for AOS in food cards
  const aosEffects = ["fade-up", "fade-down"];

  const getRandomEffect = () => {
    return aosEffects[Math.floor(Math.random() * aosEffects.length)];
  };
  // Filter foods by category and allergens
  const filteredFoods = foods.filter((food) => {
    const categoryMatch =
      selectedCategories.length === 0 ||
      selectedCategories.some((catId) => food.food_category.includes(catId));
    const allergenMatch =
      selectedAllergens.length === 0 ||
      !selectedAllergens.some((allergenId) =>
        food.food_allergens.includes(allergenId)
      );

    return categoryMatch && allergenMatch;
  });

  // Function to get the name of a category from its ID
  const getCategoryName = (categoryIds) => {
    if (!categories || categories.length === 0) {
      return "Chargement des catégories...";
    }

    if (Array.isArray(categoryIds)) {
      // If it's an array of IDs, we'll get the name of each category
      const categoryNames = categoryIds.map((categoryId) => {
        const category = categories.find(
          (cat) => cat.category_id === categoryId
        );
        return category ? category.category_name : "Catégorie inconnue";
      });

      // Return the names of the categories separated by a comma
      return categoryNames.join(", ");
    } else {
      // If it's a single ID, we'll get the name of the category
      const category = categories.find(
        (cat) => cat.category_id === categoryIds
      );
      return category ? category.category_name : "Catégorie inconnue";
    }
  };

  return (
    <div className="my-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredFoods.map((food) => (
          <div
            key={food.food_id}
            className="bg-white dark:bg-slate-300 p-4 rounded shadow-md justify-center items-center"
            data-aos={getRandomEffect()}
            data-aos-delay="50"
          >
            <img
              src={food.food_imageURL}
              alt={food.food_name}
              className="w-full h-[200px] object-contain rounded"
            />
            <p className="text-lg font-semibold">{food.food_name}</p>

            <p className="text-gray-500">
              {getCategoryName(food.food_category)}
            </p>
            <div className="flex flex-wrap gap-2 mt-2">
              {food.food_allergens && food.food_allergens.length > 0 ? (
                food.food_allergens.map((allergenId) => {
                  const allergen = allergens.find(
                    (a) => a.allergen_id === allergenId
                  );
                  if (!allergen) {
                    return (
                      <span
                        key={allergenId}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Allergène inconnu
                      </span>
                    );
                  }
                  return (
                    <span
                      key={allergenId}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      {allergen.allergen_name}
                    </span>
                  );
                })
              ) : (
                <span className="bg-green-500 text-white px-2 py-1 rounded">
                  Aucun allergène
                </span>
              )}
            </div>
            <BTNAddToBento
              food={food}
              supplements={supplements}
              allergens={allergens}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterFoodListByCategoriesAndAllergens;
