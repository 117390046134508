import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

const BTNSave = ({ submitNewData, buttonClick }) => {
  const { t } = useTranslation();
  return (
    <motion.button
      onClick={submitNewData}
      {...buttonClick}
      className="w-9/12 py-2 rounded-md bg-red-400 text-primary dark:text-primary-dark hover:bg-red-500 cursor-pointer"
    >
      {t("dbNewItem.saveButton")}
    </motion.button>
  );
};

export default BTNSave;
