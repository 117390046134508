import { api } from "../index";

// Add new category
export const addNewCategory = async (data) => {
  try {
    const res = await api.post(`/categories/create`, data);
    return res.data.data;
  } catch (err) {
    return console.log("Erreur : " + err);
  }
};

// Get all categories
export const getAllCategories = async () => {
  try {
    const res = await api.get(`/categories/all`);

    return res.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Update category
export const updateCategory = async (id, data) => {
  try {
    const res = await api.put(`/categories/update/${id}`, data);
    return res.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Delete category
export const deleteCategory = async (id) => {
  try {
    const res = await api.delete(`/categories/delete/${id}`);
    return res.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};