import { api } from "../index";

// Add new allergen
export const addNewAllergen = async (data) => {
  try {
    const res = await api.post(`/allergens/create`, data);
    return res.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Get all allergens
export const getAllAllergens = async () => {
  try {
    const res = await api.get(`/allergens/all`);

    return res.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Delete an allergen
export const deleteAllergen = async (id) => {
  try {
    const res = await api.delete(`/allergens/delete/${id}`)
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update an allergen
export const updateAllergen = async (id, data) => {
  try {
    const res = await api.put(`/allergens/update/${id}`, data);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
}