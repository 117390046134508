import { api } from "../index";

export const decryptJWT = async () => {
  try {
    const res = await api.get(`/users/decryptJWT`);
    return res.data.data;
  } catch (err) {
    return null;
  }
};


export const createUser = async (userData) => {
  try {
    const response = await api.post("/users/create", userData);
       
    return response.data;
  } catch (err) {
    console.error("Erreur lors de la création de l'utilisateur sur le backend :", err);
    
    return { success: false, msg: "Une erreur s'est produite lors de la création de l'utilisateur sur le backend." };
  }
};

export const readUsers = async () => {
  try {
    const response = await api.get("/users/read");
    return response.data.users;
  } catch (err) {
    console.error("Erreur lors de la lecture des utilisateurs :", err);
    return [];
  }
};

export const loginWithEmailAndPasswordBackEnd = async (id) => {
  try {
    const response = await api.post("/users/loginWithEmailAndPassword", { id });
    return response.data;
  } catch (err) {
    console.error("Erreur lors de la connexion :", err);
    return { success: false, msg: "Erreur lors de la connexion" };
  }
}

export const signUp = async (userData) => {
  try {
    const response = await api.post("/users/signup", userData);
    return response.data;
  } catch (err) {
    console.error("Erreur lors de l'inscription :", err);

    // Verify if the error is coming from the backend
    if (err.response && err.response.data) {
      return { success: false, msg: err.response.data.message };
    } else {
      return { success: false, msg: "Erreur lors de l'inscription : " + err.message };
    }
  }
};

export const googleLogin = async (userData) => {
  try {
    const response = await api.post("/users/googleLogin", userData);
    return response.data;
  } catch (err) {
    console.error("Erreur lors de la connexion Google :", err);
    return { success: false, msg: "Erreur lors de la connexion Google" };
  }
}


export const isAdminApi = async () => {
  try {
    const response = await api.get("/users/isAdmin");
    return response.data.isAdmin;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};

// Get All User with orders
export const getAllUsersWithOrders = async () => {
  try {
    const res = await api.get(`/users/getAllUsersWithOrders`);
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la récupération des commandes :", err);
    return null;
  }
}
