import { api } from "../index";

export const createPaymentSession = async (order, orderNumber) => {
  try {
    const payload = {
      order: order,
      orderNumber: orderNumber,
    };

    const res = await api.post("/stripe/create-payment-session", payload);
    return res.data;
  } catch (error) {
    console.error(
      "Erreur lors de la création de la session de paiement:",
      error
    );
    throw error;
  }
};

export const createPaymentIntent = async (order) => {
  try {
    const res = await api.post("/stripe/create-payment-intent", order);
    return res.data;
  } catch (error) {
    console.error(
      "Erreur lors de la création de la session de paiement:",
      error
    );
    throw error;
  }
};
