import React, { useEffect, useState } from "react";
const languages = [
  { code: "fr", name: "Français", flag: "https://flagcdn.com/32x24/fr.png" },
  { code: "en", name: "English", flag: "https://flagcdn.com/32x24/gb.png" },
  { code: "nl", name: "Nederlands", flag: "https://flagcdn.com/32x24/nl.png" },
  { code: "de", name: "Deutsch", flag: "https://flagcdn.com/32x24/de.png" },
  { code: "ja", name: "日本語", flag: "https://flagcdn.com/32x24/jp.png" },
];

const LanguageDropdownList = ({ changeLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const savedLanguageCode = localStorage.getItem("languageCode");
    if (savedLanguageCode) {
      const savedLanguage = languages.find(
        (lang) => lang.code === savedLanguageCode
      );
      if (savedLanguage) {
        setCurrentLanguage(savedLanguage);
        changeLanguage(savedLanguageCode);
      }
    }
  }, [changeLanguage]);

  const handleLanguageChange = (language) => {
    const selectedLanguage = languages.find((lang) => lang.code === language);
    if (selectedLanguage) {
      changeLanguage(language);
      setCurrentLanguage(selectedLanguage);
      localStorage.setItem("languageCode", language);
    }
    setIsOpen(false);
  };

  return (
    <div className="relative z-50 w-full">
      <button
        onClick={toggleDropdown}
        className="px-2 py-2 rounded-md border shadow-md flex items-center w-full"
      >
        <img
          src={currentLanguage.flag}
          alt={currentLanguage.name}
          className="w-8 h-6"
        />
        <span className="ml-2 text-lg">{currentLanguage.name}</span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border rounded shadow-lg">
          {languages.map((language) => (
            <div
              key={language.code}
              className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
              onClick={() => handleLanguageChange(language.code)}
            >
              <img
                src={language.flag}
                alt={language.name}
                className="w-8 h-6"
              />
              <span className="ml-2 text-xl">{language.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdownList;
