import React, { useState } from "react";
import { LoginBg, Logo } from "../assets";
import { LoginInput } from "../components";

import { FaEnvelope, FaLock, FcGoogle } from "../assets/icons";
import { motion } from "framer-motion";
import { buttonClick } from "../animations";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import {
  googleLogin,
  loginWithEmailAndPasswordBackEnd,
  signUp,
} from "../api/users/users";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { app } from "../config/firebase.config";
import { decryptJWT } from "../api/users/users";

import { useDispatch } from "react-redux";
import { setUserDetails, setUserNULL } from "../context/actions/userActions";

import CustomToastContainer from "../components/Notifications/CustomToastContainer";

const Login = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [pseudonyme, setPseudonyme] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);
  const [
    isLoadingSignInWithEmailAndPassword,
    setIsLoadingSignInWithEmailAndPassword,
  ] = useState(false);
  const { notifyToast } = CustomToastContainer();

  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginWithGoogle = async () => {
    try {
      setIsLoading(true);
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;

      await googleLogin({
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
      });

      // Update of the token with custom claims
      const updatedToken = await user.getIdToken(true);
      console.log("Token mis à jour avec custom claims:", updatedToken);

      // Now we can decrypt the token to get the user data
      const userData = await decryptJWT(updatedToken);
      if (userData) {
        dispatch(setUserDetails(userData));
        navigate("/", { replace: true });
      }

      navigate("/", { replace: true });
      setIsLoading(false);

      notifyToast(
        "success",
        "Connecté en tant que " + user.displayName,
        "idConnecteEnTantQue"
      );
    } catch (error) {
      setIsLoading(false);
      if (error.code && error.code.includes("auth/cancelled-popup-request")) {
        notifyToast(
          "warning",
          "Vous avez annulé la connexion via Google.",
          "id+googleannule"
        );
      } else {
        notifyToast(
          "warning",
          "Une erreur est survenue lors de la connexion via Google.",
          "id+erreurgoogle"
        );
      }
      console.log(error);
    }
  };

  const loginWithEmailAndPassword = async () => {
    if (userEmail !== "" && password !== "") {
      setIsLoadingSignInWithEmailAndPassword(true);

      try {
        // API call to login
        const userCred = await signInWithEmailAndPassword(
          firebaseAuth,
          userEmail,
          password
        );

        const user = userCred.user;
        console.log(user);
        console.log(user.uid);
        const response = await loginWithEmailAndPasswordBackEnd(user.uid);
        console.log(response);
        if (response.success) {
          const token = await user.getIdToken();
          const data = await decryptJWT(token);
          dispatch(setUserNULL());
          dispatch(setUserDetails(data));
          navigate("/", { replace: true });
          notifyToast("success", "Connecté avec succès", "idConnexionReussie");
        } else {
          notifyToast("warning", response.message, "idErreurConnexion");
        }
      } catch (error) {
        notifyToast(
          "error",
          "Une erreur est survenue lors de la connexion.",
          "idErreurConnexionCatch"
        );
      } finally {
        setIsLoadingSignInWithEmailAndPassword(false);
      }
    } else {
      notifyToast(
        "warning",
        "Veuillez remplir tous les champs",
        "idChampsVidesConnexion"
      );
      setIsLoadingSignInWithEmailAndPassword(false);
    }
  };

  const signUpWithEmailAndPassword = async () => {
    if (
      userEmail === "" ||
      password === "" ||
      confirm_password === "" ||
      pseudonyme === ""
    ) {
      notifyToast(
        "info",
        "Complétez tous les champs",
        "idCompletezTousLesChampsSignUpWithEmailAndPassword"
      );
    } else {
      if (password === confirm_password) {
        try {
          setIsLoadingSignUp(true);
          // Call to the API for sign up
          const response = await signUp({
            email: userEmail,
            password: password,
            displayName: pseudonyme,
          });

          if (response.success) {
            // If the sign up is successful, we can log in the user
            setIsLoadingSignUp(false);
            notifyToast(
              "success",
              "Inscription réussie ! Vous pouvez désormais vous connecter.",
              "idInscriptionReussie"
            );
            setIsSignUp(false);
          } else {
            // Else, we display the error message
            setIsLoadingSignUp(false);
            notifyToast("error", response.msg, "idErreurInscription");
          }
        } catch (error) {
          setIsLoadingSignUp(false);
          notifyToast(
            "error",
            "Une erreur est survenue lors de l'inscription : " + error.message,
            "idErreurInscriptionCatch"
          );
        }
      } else {
        notifyToast(
          "warning",
          "Les mots de passe ne correspondent pas !",
          "idLesMotsDePassesNeCorrespondentPas"
        );
      }
    }
  };

  return (
    <div className="w-screen  h-[100%] md:h-screen relative overflow-hidden flex">
      {/* background image */}
      <img
        src={LoginBg}
        className="w-full h-full object-cover absolute top-0 left-0"
        alt="Test"
      />

      {/* content box */}
      <div className="flex flex-col items-center bg-cstmLoginBackground dark:bg-cstmLoginBackground-dark w-[100%] md:w-508 h-full z-10  backdrop-blur-sm p-4 px-4 py-12 gap-6">
        {/* top logo section */}
        <div className="flex items-center justify-center gap-4 w-full">
          <img src={Logo} className="w-10" alt="" />
          <p className="text-cstmHeadingColor dark:text-cstmHeadingColor-dark font-semibold text-3xl">
            uBento
          </p>
        </div>

        <p className="text-3xl font-semibold text-cstmHeadingColor dark:text-cstmHeadingColor-dark">
          Bienvenue
        </p>

        <p className="text-xl -mt-4">
          {isSignUp ? "S'inscrire" : "Se connecter"}
        </p>

        {/* input section */}
        <div className="w-full flex flex-col items-center justify-center gap-6 px-4 md:px-12 py-4">
          <LoginInput
            placeHolder={"Email"}
            icon={<FaEnvelope className="text-xl " />}
            inputState={userEmail}
            inputStateFunc={setUserEmail}
            type="email"
            isSignUp={isSignUp}
          />

          <LoginInput
            placeHolder={"Mot de passe"}
            icon={<FaLock className="text-xl " />}
            inputState={password}
            inputStateFunc={setPassword}
            type="password"
            isSignUp={isSignUp}
          />

          {isSignUp && (
            <>
              <LoginInput
                placeHolder={"Confirmer Mot de passe"}
                icon={<FaLock className="text-xl " />}
                inputState={confirm_password}
                inputStateFunc={setConfirm_password}
                type="password"
                isSignUp={isSignUp}
              />
              <LoginInput
                placeHolder={"Pseudonyme"}
                icon={<FaUser className="text-xl " />}
                inputState={pseudonyme}
                inputStateFunc={setPseudonyme}
                type="text"
                isSignUp={isSignUp}
              />
            </>
          )}

          {/* Button Section */}
          {isSignUp ? (
            <motion.button
              className={`w-full rounded-md px-4 py-2 bg-red-400 cursor-pointer text-white text-xl capitalize hover:bg-red-500 transition-all duration-150 ${
                isLoadingSignUp ? "pointer-events-none" : ""
              }`}
              {...buttonClick}
              onClick={signUpWithEmailAndPassword}
            >
              S'inscrire
            </motion.button>
          ) : (
            <motion.button
              className={`w-full rounded-md px-4 py-2 bg-red-400 cursor-pointer text-white text-xl capitalize hover:bg-red-500 transition-all duration-150 ${
                isLoadingSignInWithEmailAndPassword ? "pointer-events-none" : ""
              }`}
              {...buttonClick}
              onClick={loginWithEmailAndPassword}
            >
              Se connecter
            </motion.button>
          )}

          {!isSignUp ? (
            <p className=" w-full text-white text-xl capitalize   rounded-md  text-center">
              <motion.button
                {...buttonClick}
                className="w-full rounded-md px-4 py-2 bg-blue-600 cursor-pointer text-white text-xl capitalize hover:bg-blue-700 transition-all duration-150"
                onClick={() => setIsSignUp(true)}
              >
                Créer un compte
              </motion.button>
            </p>
          ) : (
            <p className="w-full flex text-white text-xl capitalize   rounded-md ">
              <motion.button
                {...buttonClick}
                className="w-full rounded-md px-4 py-2 bg-blue-600 cursor-pointer text-white text-xl capitalize hover:bg-blue-700 transition-all duration-150"
                onClick={() => setIsSignUp(false)}
              >
                Déjà inscrit ? Se connecter
              </motion.button>
            </p>
          )}
        </div>

        <div className="flex items-center justify-between gap-16">
          <div className="w-24 h-[1px] rounded-md bg-white"></div>
          <p className="text-white">ou</p>
          <div className="w-24 h-[1px] rounded-md bg-white"></div>
        </div>

        <div
          className={`w-full flex flex-col items-center justify-center gap-6 px-4 md:px-12 py-4 ${
            isLoading ? "pointer-events-none" : ""
          }`}
        >
          <motion.div
            {...buttonClick}
            className="w-full flex items-center justify-center  py-2 bg-white backdrop-blur-md cursor-pointer rounded-3xl gap-2"
            onClick={loginWithGoogle}
          >
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <FcGoogle className="text-3xl" />
            )}
            {isLoading ? <p>Chargement...</p> : <p>Se connecter avec Google</p>}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Login;
