const storedCartItems = localStorage.getItem("cartItems");
const initialState = {
  items: storedCartItems ? JSON.parse(storedCartItems) : [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItemIndex = state.items.findIndex(
        (item) => getItemIdentifier(item) === getItemIdentifier(action.payload)
      );

      if (existingItemIndex !== -1) {
        // Exist already, update quantity
        const updatedItems = state.items.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );

        return {
          ...state,
          items: updatedItems,
        };
      } else {
        // Not exist, add to cart
        return {
          ...state,
          items: [...state.items, action.payload],
        };
      }

    case "REMOVE_FROM_CART":
      const updatedItemsAfterRemove = state.items.filter(
        (item) => getItemIdentifier(item) !== action.payload
      );
      // Save the new cart state to localStorage
      localStorage.setItem(
        "cartItems",
        JSON.stringify(updatedItemsAfterRemove)
      );

      return {
        ...state,
        items: updatedItemsAfterRemove,
      };

    case "UPDATE_CART":
      const updatedItems = state.items.map((item) =>
        getItemIdentifier(item) === action.payload.itemIdentifier
          ? { ...item, quantity: action.payload.newQuantity }
          : item
      );
      // Save the new cart state to localStorage
      localStorage.setItem("cartItems", JSON.stringify(updatedItems));

      return {
        ...state,
        items: updatedItems,
      };

    case "RESET_CART":
      return {
        ...state,
        items: [], // Reset cart items to empty array
      };

    default:
      return state;
  }
};

export const getItemIdentifier = (item) => {
  const supplementsIds = item.supplements
    .map((supplement) => supplement.supplement_id)
    .sort(); // sort the array to ensure consistent ordering

  return `${item.food.food_id}-${JSON.stringify(supplementsIds)}`;
};

export default cartReducer;
