import React, { useState } from "react";
import InputValueField from "../InputValueField";
import BTNSave from "../../Buttons/BTNSave";
import { addNewCategory } from "../../../api/categories/categories";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";

const DBAjoutCategory = () => {
  const [category, setCategory] = useState("");
  const { notifyToast } = CustomToastContainer();
  const [isAddingCategoryLoading, setIsAddingCategoryLoading] = useState(false);
  const { t } = useTranslation();

  const submitNewData = () => {
    if (!category.trim()) {
      notifyToast(
        "error",
        t("dbAjoutCategory.errorEmptyCategoryName"),
        "idErrorEntrezUnNomPourAddCategory"
      );

      return;
    }

    if (isAddingCategoryLoading) {
      return;
    }

    setIsAddingCategoryLoading(true);
    const data = {
      category_name: category,
    };
    addNewCategory(data)
      .then((res) => {
        notifyToast(
          "success",
          `${t("dbAjoutCategory.newCategory")} "${category}" ${t(
            "dbAjoutCategory.added"
          )}`,
          "idSuccessNewCategorieAdded"
        );

        setCategory("");
      })
      .catch((error) => {
        console.error(t("dbAjoutCategory.errorWhenAddingNewSauce"), error);
      })
      .finally(() => {
        setIsAddingCategoryLoading(false);
      });
  };
  return (
    <div className="flex lg:px-12 justify-center items-center flex-col pt-6 w-full ">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600">
        {t("dbAjoutCategory.pageTitle")}
      </h1>
      <div className="border   rounded-lg p-4 w-full flex flex-col items-center justify-center gap-4 bg-white dark:bg-slate-100">
        <InputValueField
          type="text"
          placeHolder={t("dbAjoutCategory.inputLabel")}
          stateFunction={setCategory}
          stateValue={category}
        />

        <BTNSave
          submitNewData={submitNewData}
          loading={isAddingCategoryLoading}
        />
      </div>
    </div>
  );
};

export default DBAjoutCategory;
