import React, { useEffect } from "react";
import { motion } from "framer-motion";
import bento_img from "../assets/img/bento.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BTNGradient from "./Buttons/BTNGradient";
import CustomToastContainer from "./Notifications/CustomToastContainer";
import { useDispatch } from "react-redux";
import { resetCart } from "../context/actions/cartActions";
const Home = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { notifyToast } = CustomToastContainer();
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    // Check if the user has been redirected from the payment page
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get("paymentStatus");
    if (paymentStatus === "cancel") {
      notifyToast(
        "error",
        t("home.orderPaidCanceled"),
        "idUserOrderPaidCanceled"
      );
    }
  }, [location]);

  return (
    <motion.div className="w-full grid grid-cols-1 2xl:grid-cols-2 gap-12 select-none 2xl:mt-30 md:mt-10 sm:mt-8 px-6 ">
      <div
        className="flex flex-col items-center justify-start gap-3 "
        data-aos="fade-zoom-in"
      >
        <p className="text-[9vw] md:text-[8vw] xl:text-[3vw] font-sans font-extrabold dark:text-headingColor-dark">
          {t("home.orderYour")}
        </p>
        <p className="text-red-600 dark:text-red-600 text-[9vw] md:text-[8vw] xl:text-[5vw] font-sans font-extrabold">
          {t("home.bento")}
        </p>
        <p className="text-lg text-justify dark:text-textColor-dark">
          {t("home.culinaryAdventure")}
        </p>
        <div className="justify-center items-center ">
          <Link to="/menu">
            <motion.div whileHover={{ scale: 1.25 }} whileTap={{ scale: 0.95 }}>
              <BTNGradient>{t("home.seeMenu")}</BTNGradient>
            </motion.div>
          </Link>
        </div>
      </div>

      <div
        className="flex justify-center items-center"
        data-aos="fade-zoom-in"
        data-aos-delay="300"
      >
        <motion.div
          animate={{ y: ["-2%", "2%", "-2%", "2%", "-2%"] }}
          transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
        >
          <img
            src={bento_img}
            alt={t("home.deliciousBento")}
            className="object-cover w-full h-full"
            draggable="false"
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Home;
