import React from "react";
import { Link } from "react-router-dom";

const UserProfile = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center mt-36">
      <h2 className="text-3xl font-semibold mb-4">En construction 👨‍🏭 </h2>
      <p className="text-xl">Merci de revenir plus tard...</p>
      <Link to={"/"}>
        <button className="border border-red-500 rounded-md px-4 py-2 bg-red-500 text-white mt-4">
          Retour
        </button>
      </Link>
    </div>
  );
};

export default UserProfile;
