import { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../context/actions/cartActions";
import { getItemIdentifier } from "../../context/reducers/cartReducer";
import CloseIcon from "../CloseIcon";
import CustomToastContainer from "../Notifications/CustomToastContainer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BTNAddToBento = ({ food, supplements, onAddToCart, allergens }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [selectedSupplements, setSelectedSupplements] = useState([]);

  const isAuthenticated = useSelector((state) => state.user);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();

  const resetModalState = () => {
    setSelectedSupplements([]);
  };

  const foodSupplements = supplements.filter((supplement) =>
    food.food_supplements.includes(supplement.supplement_id)
  );

  const handleAddToBento = () => {
    const itemIdentifier = getItemIdentifier({
      food: food,
      supplements: selectedSupplements,
    });

    dispatch(
      addToCart({
        food: food,
        quantity: quantity,
        supplements: selectedSupplements,
        itemIdentifier: itemIdentifier,
      })
    );

    setModalIsOpen(false);

    const supplementNames = selectedSupplements
      .map((supplement) => supplement.supplement_name)
      .join(", ");

    if (quantity <= 1 && selectedSupplements.length === 0) {
      notifyToast(
        "success",
        `${quantity} ${food.food_name} ${t("btnAddToBento.itemAdded")}`,
        "idQuantiteFoodAddToBento",
        true
      );
    } else if (quantity <= 1 && selectedSupplements.length > 0) {
      notifyToast(
        "success",
        `${quantity} ${food.food_name} ${t(
          "btnAddToBento.withSupplement"
        )} ${supplementNames} ${t("btnAddToBento.itemsAdded")}`,
        "idQuantiteFoodAddToBentoTwo",
        true
      );
    } else if (quantity > 1 && selectedSupplements.length === 0) {
      notifyToast(
        "success",
        `${quantity} ${food.food_name} ${t("btnAddToBento.itemsAdded")}`,
        "idQuantiteFoodAddToBentoThree",
        true
      );
    } else {
      notifyToast(
        "success",
        `${quantity} ${food.food_name} ${t(
          "btnAddToBento.withSupplement"
        )} ${supplementNames} ${t("btnAddToBento.itemsAdded")}`,
        "idQuantiteFoodAddToBentoFour",
        true
      );
    }

    setQuantity(1);
    setSelectedSupplements([]);
  };

  const selectedAllergens = selectedSupplements
    .flatMap((sup) => sup.supplement_allergens || [])
    .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates

  const AllergenInfoBubble = () => {
    const allergenNames = selectedAllergens
      .map((allergenId) => {
        const allergen = allergens.find((a) => a.allergen_id === allergenId);
        return allergen ? allergen.allergen_name : null;
      })
      .filter((name, index, self) => name && self.indexOf(name) === index);

    return (
      allergenNames.length > 0 && (
        <div className="bg-yellow-100 border rounded-lg border-yellow-500 text-yellow-700 p-2 mt-2 text-center text-sm">
          <p className="font-bold">Attention</p>
          <p>
            Ces suppléments contiennent les allergènes suivants :{" "}
            <span className="font-bold">{allergenNames.join(", ")}.</span>
          </p>
        </div>
      )
    );
  };

  return (
    <>
      {isAuthenticated ? (
        <button
          onClick={() => setModalIsOpen(true)}
          className="mt-4 w-full bg-red-500 text-white rounded px-4 py-2 transition duration-300 ease-in-out hover:bg-red-600"
        >
          {t("btnAddToBento.addToBento")}
        </button>
      ) : (
        <p className="mt-4 text-red-500 text-center">
          {t("btnAddToBento.mustSignIn")}{" "}
          <Link to="/login" className="underline">
            {t("btnAddToBento.signIn")}
          </Link>{" "}
          {t("btnAddToBento.toAddFood")}
        </p>
      )}

      <Modal
        open={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
          resetModalState();
        }}
        center
        closeIcon={<CloseIcon />}
      >
        <div className="p-4">
          <h2 className="font-semibold text-center mb-2 text-3xl">
            {food.food_name}
          </h2>
          <div className="mb-4 text-center">
            <p className="text-2xl font-bold">{food.food_price} €</p>
          </div>

          <img
            src={food.food_imageURL}
            alt={food.food_name}
            className="w-full h-64 object-cover mb-4 rounded"
          />

          <div className="mb-4">
            <label className="font-semibold">
              {t("btnAddToBento.quantity")}
            </label>
            <input
              type="number"
              min="1"
              defaultValue="1"
              className="w-full p-2 border rounded"
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
          </div>

          {foodSupplements.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2">
                {t("btnAddToBento.supplements")}
              </h3>{" "}
            </div>
          )}
          {foodSupplements.map((supplement) => (
            <div
              key={supplement.supplement_id}
              className="flex items-center mb-2"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSupplements([
                          ...selectedSupplements,
                          supplement,
                        ]);
                      } else {
                        setSelectedSupplements(
                          selectedSupplements.filter(
                            (supp) =>
                              supp.supplement_id !== supplement.supplement_id
                          )
                        );
                      }
                    }}
                  />
                }
                label={supplement.supplement_name}
              />
              <img
                src={supplement.imageURL}
                alt={supplement.supplement_name}
                className="w-16 h-16 ml-2  object-contain rounded"
              />
            </div>
          ))}
          <AllergenInfoBubble />

          {/* Price */}
          <div className="text-center mb-4 mt-8">
            <h4 className="font-semibold">{t("btnAddToBento.totalPrice")}</h4>
            <p className="text-2xl font-bold">
              {(quantity * food.food_price).toFixed(2)} €
            </p>
          </div>
          <button
            onClick={() => handleAddToBento(food)}
            className="mt-4 w-full bg-green-500 text-white rounded px-4 py-2 transition duration-300 ease-in-out hover:bg-green-600"
          >
            {t("btnAddToBento.addToBento")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BTNAddToBento;
