import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard, Footer, Login, Main } from "./containers";
import { getAuth } from "firebase/auth";
import { app } from "./config/firebase.config";
import { decryptJWT, isAdminApi } from "./api/users/users";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "./context/actions/userActions";
import { motion } from "framer-motion";
import { fadeInOut } from "./animations";
import { Alert, MainLoader } from "./components";
import AccessDenied from "./containers/AccessDenied";
import User from "./containers/User";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "react-toastify/dist/ReactToastify.css";
import "./translations/i18n";

const App = () => {
  const firebaseAuth = getAuth(app);

  const [isLoading, setIsLoading] = useState(true);
  const alert = useSelector((state) => state.alert);

  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (cred) => {
      if (cred) {
        // Force the user's ID token to be refreshed
        const token = await cred.getIdToken(true);
        localStorage.setItem("token", token);
        const userData = await decryptJWT(token);
        console.log(userData);
        if (userData) {
          dispatch(setUserDetails(userData));
          setUser(userData);
          const isAdminStatus = await isAdminApi();
          setIsAdmin(isAdminStatus);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
        localStorage.removeItem("token");
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <Elements stripe={stripePromise}>
      <div className="w-screen min-h-screen h-auto flex flex-col items-center justify-center">
        {isLoading && (
          <motion.div
            {...fadeInOut}
            className="fixed z-50 inset-0  backdrop-blur-md flex items-center justify-center w-full"
          >
            <MainLoader />
          </motion.div>
        )}
        {!isLoading && (
          <>
            <Routes>
              <Route path="/*" element={<Main isAdmin={isAdmin} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/user/*" element={<User isAdmin={isAdmin} />} />

              <Route
                path="/dashboard/*"
                element={isAdmin ? <Dashboard /> : <AccessDenied />}
              />
            </Routes>
            {alert?.type && (
              <Alert type={alert?.type} message={alert?.message} />
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <Footer />
          </>
        )}
      </div>
    </Elements>
  );
};

export default App;
