import { api } from "../index";

// Add new food
export const addNewFood = async (data) => {
  try {
    const res = await api.post(`/foods/create`, data);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get all food
export const getAllFoods = async () => {
  try {
    const res = await api.get(`/foods/all`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get all food with full details
export const getFullFoodsDetails = async () => {
  try {
    const res = await api.get(`/foods/getFullFoodsDetails`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Delete food
export const deleteFood = async (id) => {
  try {
    const res = await api.delete(`/foods/delete/${id}`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update food
export const updateFood = async (id, data) => {
  try {
    const res = await api.put(`/foods/update/${id}`, data);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
