import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  getAllOrders,
  updateOrderStatus,
  deleteOrder,
} from "../../../api/orders/orders";
import DBOrdersList from "./DBOrdersList";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import DBOrdersViewToggle from "./DBOrdersViewToggle";
import DBOrdersFilter from "./DBOrdersFilter";

const DBOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [viewType, setViewType] = useState("list");
  const [filters, setFilters] = useState({
    status: null,
    time: null,
    price: null,
  });
  const [statusOptions, setStatusOptions] = useState([]);
  const { t } = useTranslation();
  const { notifyToast } = CustomToastContainer();
  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getAllOrders();
        setOrders(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchOrders();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilters({ status: null, time: null, price: null });
  };

  // Update filtered orders when orders or selectedDate changes
  useEffect(() => {
    const selectedDateUTCPlusOne = moment(selectedDate)
      .utcOffset("+0100")
      .format("YYYY-MM-DD");
    const filtered = orders.filter((order) =>
      order.date.startsWith(selectedDateUTCPlusOne)
    );
    setFilteredOrders(filtered);
  }, [orders, selectedDate]);

  const handleFilterChange = (filterType, selectedOption) => {
    setFilters({
      ...filters,
      [filterType]: selectedOption ? selectedOption.value : null,
    });
  };

  useEffect(() => {
    const selectedDateFormatted = moment(selectedDate).format("YYYY-MM-DD");

    // Filter first by date
    let filteredByDate = orders.filter(
      (order) =>
        moment(order.date).format("YYYY-MM-DD") === selectedDateFormatted
    );

    // Then sort by time
    let sortedByTime = filteredByDate.sort((a, b) => {
      const timeA = moment(a.date, "YYYY-MM-DD HH:mm");
      const timeB = moment(b.date, "YYYY-MM-DD HH:mm");
      return timeA - timeB;
    });

    // Apply other filters
    if (filters.time) {
      sortedByTime = sortedByTime.filter(
        (order) => moment(order.date).format("HH:mm") === filters.time
      );
    }
    if (filters.status) {
      sortedByTime = sortedByTime.filter(
        (order) => order.statut === filters.status
      );
    }
    if (filters.price) {
      sortedByTime = sortedByTime.sort((a, b) =>
        filters.price === "ascending"
          ? a.orderTotal - b.orderTotal
          : b.orderTotal - a.orderTotal
      );
    }

    setFilteredOrders(sortedByTime);
  }, [filters, orders, selectedDate]);

  const priceOptions = [
    { value: "ascending", label: t("DBOrders.priceAscending") },
    { value: "descending", label: t("DBOrders.priceDescending") },
  ];

  useEffect(() => {
    const newTimeOptions = Array.from(
      new Set(filteredOrders.map((order) => moment(order.date).format("HH:mm")))
    ).map((time) => ({ value: time, label: time }));

    setTimeOptions(newTimeOptions);
  }, [filteredOrders]);

  useEffect(() => {
    const newStatusOptions = Array.from(
      new Set(filteredOrders.map((order) => order.statut))
    ).map((status) => ({
      value: status,
      label: t(`DBOrders.orderStatus.${status}`),
    }));

    setStatusOptions(newStatusOptions);
  }, [filteredOrders, t]);

  const handleUpdateOrderStatus = async (orderNumber, newStatus) => {
    try {
      await updateOrderStatus(orderNumber, newStatus);
      const updatedOrders = orders.map((order) =>
        order.orderNumber === orderNumber
          ? { ...order, statut: newStatus }
          : order
      );
      setOrders(updatedOrders);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteOrder = async (orderNumber) => {
    try {
      await deleteOrder(orderNumber);
      const updatedOrders = orders.filter(
        (order) => order.orderNumber !== orderNumber
      );
      setOrders(updatedOrders);
      // update filtered orders
      const updatedFilteredOrders = filteredOrders.filter(
        (order) => order.orderNumber !== orderNumber
      );
      setFilteredOrders(updatedFilteredOrders);
      notifyToast("success", t("DBOrders.orderDeleted"));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col w-full">
        <div className="max-w-4xl lg:p-4 ">
          <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
            {t("DBOrders.yourOrders")}
          </h1>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            className="mb-2"
          />
        </div>
      </div>
      <div className=" lg:px-20 ">
        <div className="flex justify-between p-2 mb-4">
          <DBOrdersFilter
            statusOptions={statusOptions}
            timeOptions={timeOptions}
            priceOptions={priceOptions}
            handleFilterChange={handleFilterChange}
            currentFilters={filters}
          />

          <DBOrdersViewToggle viewType={viewType} setViewType={setViewType} />
        </div>
        <DBOrdersList
          orders={filteredOrders}
          viewType={viewType}
          updateOrderStatus={handleUpdateOrderStatus}
          deleteOrder={handleDeleteOrder}
        />
      </div>
    </>
  );
};

export default DBOrders;
