import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import Select from "react-select";

const DBSupplementCard = ({
  supplement,
  onDelete,
  onUpdate,
  allergenOptions,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const initialAllergens = supplement.supplement_allergens.map((allergen) => ({
    value: allergen.id,
    label: allergen.name,
  }));

  const [editedSupplement, setEditedSupplement] = useState({
    ...supplement,
    supplement_allergens: initialAllergens,
  });
  const { t } = useTranslation();
  const handleOpenModal = () => setModalOpen(true);

  const handleDeleteClick = () => {
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(supplement.supplement_id);
    setDeleteConfirmModalOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleChange = (value, field) => {
    if (field === "supplement_allergens") {
      setEditedSupplement({
        ...editedSupplement,
        [field]: value,
      });
    } else {
      setEditedSupplement({
        ...editedSupplement,
        [field]: value.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedSupplementData = {
      ...editedSupplement,
      supplement_allergens: editedSupplement.supplement_allergens.map(
        (option) => {
          return { id: option.value, name: option.label };
        }
      ),
    };

    onUpdate(updatedSupplementData);
    setModalOpen(false);
  };

  const renderAllergenBadges = () => {
    return supplement.supplement_allergens.map((allergen) => (
      <span
        key={allergen.id}
        className="bg-red-200 text-red-700 px-2 py-1 rounded mr-2"
      >
        {allergen.name}
      </span>
    ));
  };

  return (
    <div className="border rounded overflow-hidden shadow-lg bg-white dark:bg-slate-100">
      <div>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">
            <p className="text-center">{supplement.supplement_name}</p>
          </div>
        </div>
        {supplement.imageURL && (
          <img
            src={supplement.imageURL}
            alt={supplement.supplement_name}
            className="w-32 h-32 object-cover mx-auto rounded-full"
          />
        )}
      </div>

      <div className="p-4">
        <div className="font-bold mb-2">{t("DBSupplementCard.allergens")}</div>
        <div className="flex flex-wrap">{renderAllergenBadges()}</div>
      </div>

      <div className="flex space-x-2 mt-4 p-4">
        <button
          onClick={handleOpenModal}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {t("DBSupplementCard.update")}
        </button>
        <button
          onClick={handleDeleteClick}
          className="bg-red-500 text-white p-2 rounded"
        >
          {t("DBSupplementCard.delete")}
        </button>
      </div>

      {/* Modal for update */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <form onSubmit={handleSubmit} className="p-4 h-screen">
          <h2 className="text-xl font-bold mb-4">
            {t("DBSupplementCard.updateSupplement")} :{" "}
            {supplement.supplement_name}
          </h2>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("DBSupplementCard.supplementName")}
            </label>
            <input
              type="text"
              value={editedSupplement.supplement_name}
              onChange={(e) => handleChange(e, "supplement_name")}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("DBSupplementCard.allergens")}
            </label>
            <Select
              isMulti
              value={editedSupplement.supplement_allergens}
              onChange={(selected) =>
                handleChange(selected, "supplement_allergens")
              }
              options={allergenOptions}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {t("DBSupplementCard.update")}
          </button>
        </form>
      </Modal>

      {/* Modal for delete */}
      <Modal
        open={deleteConfirmModalOpen}
        onClose={handleDeleteModalClose}
        center
      >
        <div className="w-full">
          <h2 className="text-2xl font-bold mb-4">
            {t("DBSupplementCard.deleteTheSupplement")}
          </h2>
          <p>{t("DBSupplementCard.deleteConfirm")}</p>
          <div className="flex justify-around mt-4">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
              onClick={handleDeleteConfirm}
            >
              {t("DBSupplementCard.delete")}
            </button>
            <button
              className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition duration-300"
              onClick={handleDeleteModalClose}
            >
              {t("DBSupplementCard.cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DBSupplementCard;
