import React from "react";

const InputValueField = ({
  label,
  type,
  placeHolder,
  stateValue,
  stateFunction,
}) => {
  return (
    <div className="flex items-center w-full mb-4">
      {label && (
        <label className="w-1/5 text-sm font-medium text-gray-700 mr-3 text-right">
          {label}
        </label>
      )}
      <div className={`w-${label ? "full" : "full"}`}>
        <input
          type={type}
          placeholder={placeHolder}
          className="w-full px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
          value={stateValue}
          onChange={(e) => stateFunction(e.target.value)}
        />
      </div>
    </div>
  );
};

export default InputValueField;
