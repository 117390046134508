import React from "react";
import { Header, Home } from "../components";
import { Menu, MenuALaCarte, MenuParCategorie } from "../components/Menu";
import { Route, Routes, useLocation } from "react-router-dom";
import RestaurantInfos from "../components/RestaurantInfos/RestaurantInfos";

const Main = ({ isAdmin }) => {
  const location = useLocation();
  const isJustifyCenter = ["/", "/user/orders"].includes(location.pathname);

  return (
    <main
      className={`w-screen min-h-screen flex items-center ${
        isJustifyCenter ? "justify-center" : "justify-start"
      } flex-col bg-primary dark:bg-primary-dark `}
    >
      <Header isAdmin={isAdmin} />

      <div className="w-full flex flex-col items-start justify-center px-4 md:px-24 2xl:px-96 gap-12 pb-24 mt-28">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/restaurant" element={<RestaurantInfos />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/menuALaCarte" element={<MenuALaCarte />} />
          <Route path="/menuParCategorie" element={<MenuParCategorie />} />
        </Routes>
      </div>
    </main>
  );
};

export default Main;
