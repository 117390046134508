const BTNGradient = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-600 px-12 py-5 mt-5 rounded-full text-white text-base font-semibold shadow-lg  hover:shadow-xl   hover:from-pink-600 hover:via-red-600 hover:to-yellow-600 dark:from-cyan-700 dark:via-cyan-800 dark:to-cyan-900 
        dark:hover:from-cyan-800 dark:hover:via-cyan-900 dark:hover:to-teal-900
        "
    >
      {children}
    </button>
  );
};

export default BTNGradient;
