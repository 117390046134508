import React, { useEffect, useState } from "react";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../config/firebase.config";

import { motion } from "framer-motion";
import { buttonClick } from "../../../animations";
import { addNewFood } from "../../../api/foods/foods";
import SupplementsList from "../DBSupplements/DBSupplementList";
import { InputValueField } from "..";
import ImageUpload from "../ImageUpload";
import DBCategoriesList from "../DBAjoutCategory/DBCategoriesList";
import DBAllergensList from "../DBAjoutAllergens/DBAllergensList";

import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";
import { getAllSupplements } from "../../../api/supplements/supplements";
const DBNewFood = () => {
  const [itemName, setItemName] = useState("");
  const [category, setCategory] = useState([]);
  const [allergen, setAllergen] = useState([]);
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [imageDownloadURL, setImageDownloadURL] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const handleSupplementChange = (supplementId, isChecked) => {
    if (isChecked) {
      setSelectedSupplements([...selectedSupplements, supplementId]);
    } else {
      setSelectedSupplements(
        selectedSupplements.filter((id) => id !== supplementId)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allSupplementIds = supplements.map(
        (supplement) => supplement.supplement_id
      );
      setSelectedSupplements(allSupplementIds);
      let newState = {};
      allSupplementIds.forEach((id) => (newState[id] = true));
      setCheckboxState(newState);
    } else {
      setSelectedSupplements([]);
      setCheckboxState({});
    }
  };

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const supplementsFromAPI = await getAllSupplements();
        setSupplements(supplementsFromAPI);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des suppléments : ",
          error
        );
      }
    };

    fetchSupplements();
  }, []);
  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(
      storage,
      `Images/foods/${Date.now()}_${imageFile.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        notifyToast(
          "error",
          `${t("dbNewItem.error")} ${error}`,
          "idErrorUploadBytes305823058"
        );
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageDownloadURL(downloadURL);
          setIsLoading(false);
          setProgress(null);
          notifyToast(
            "success",
            t("dbNewItem.successImageUpload"),
            "idSuccessUploadTask35983968"
          );
        });
      }
    );
  };

  const deleteImageFromFirebase = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, imageDownloadURL);
    deleteObject(deleteRef).then(() => {
      setImageDownloadURL(null);
      setIsLoading(false);
      notifyToast(
        "success",
        t("dbNewItem.successImageDelete"),
        "idImageSupprimee49056384964739"
      );
    });
  };

  const submitNewData = () => {
    if (!itemName.trim()) {
      notifyToast(
        "error",
        t("dbNewItem.errorEmptyItemName"),
        "idErrorSubmitData486943863404980"
      );

      return;
    }

    if (isSubmitLoading) {
      return;
    }

    setIsSubmitLoading(true);
    const data = {
      food_name: itemName,
      food_category: category,
      food_price: price,
      food_supplements: selectedSupplements,
      food_imageURL: imageDownloadURL,
      food_allergens: allergen,
    };
    addNewFood(data)
      .then((res) => {
        notifyToast(
          "success",
          `${t("dbNewItem.newFood")} "${itemName}" ${t("dbNewItem.added")}`,
          "idNouvelAlimentAjoute4165456456"
        );
        setImageDownloadURL(null);
        setItemName("");
        setPrice("");
        setCategory([]);
        setSelectedSupplements([]);
        setAllergen([]);
      })
      .catch((error) => {
        console.error(t("dbNewItem.errorAddFood"), error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center flex-col pt-6 lg:px-24 w-full ">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600">
        {t("dbNewItem.addNewItemTitle")}
      </h1>
      <div className="border  rounded-lg p-4 w-full  flex flex-col items-center justify-center gap-4 bg-white dark:bg-slate-100">
        <InputValueField
          type="text"
          placeHolder={t("dbNewItem.itemNamePlaceholder")}
          stateFunction={setItemName}
          stateValue={itemName}
        />

        <DBCategoriesList
          setCategory={setCategory}
          selectedCategories={category}
        />

        <InputValueField
          type="number"
          placeHolder={t("dbNewItem.pricePlaceholder")}
          stateFunction={setPrice}
          stateValue={price}
        />

        <SupplementsList
          supplements={supplements}
          selectedSupplements={selectedSupplements}
          handleSupplementChange={handleSupplementChange}
          handleSelectAllChange={handleSelectAllChange}
        />

        <DBAllergensList
          setAllergen={setAllergen}
          selectedAllergens={allergen}
        />

        <ImageUpload
          isLoading={isLoading}
          progress={progress}
          imageDownloadURL={imageDownloadURL}
          uploadImage={uploadImage}
          deleteImageFromFirebase={deleteImageFromFirebase}
        />

        <motion.button
          onClick={submitNewData}
          loading={isSubmitLoading}
          {...buttonClick}
          className="w-9/12 py-2 rounded-md bg-red-400 text-primary dark:text-primary-dark hover:bg-red-500 cursor-pointer"
        >
          {t("dbNewItem.saveButton")}
        </motion.button>
      </div>
    </div>
  );
};

export default DBNewFood;
