import { api } from "../index";

export const createOrder = async (order, userId) => {
  order.userId = userId;

  try {
    const response = await api.post("/orders/create", order);

    console.log("Réponse de Firestore :", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de l'enregistrement de la commande dans Firestore :",
      error
    );

    throw error;
  }
};

// Get order by user id
export const getUserOrders = async (userId) => {
  try {
    const res = await api.get(`/orders/getByUserId/${userId}`);
    return res.data;
  } catch (err) {
    console.error(
      "Erreur lors de la récupération des commandes de l'utilisateur :",
      err
    );
    return null;
  }
};

// get available slots
export const getAvailableSlots = async (startDate, endDate) => {
  try {
    const response = await api.post(`/orders/getAvailableSlots`, {
      startDate,
      endDate,
    });

    if (response.status !== 200) {
      throw new Error(
        "Erreur lors de la récupération des créneaux horaires disponibles"
      );
    }

    return response.data;
  } catch (err) {
    console.error(
      "Erreur lors de la récupération des créneaux horaires disponibles :",
      err
    );
    throw err;
  }
};

// Get all orders
export const getAllOrders = async () => {
  try {
    const res = await api.get(`/orders/getAllOrders`);
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la récupération des commandes :", err);
    return null;
  }
};

// Update order status
export const updateOrderStatus = async (orderId, newStatus) => {
  try {
    const res = await api.put(`/orders/updateStatus/${orderId}`, {
      status: newStatus,
    });
    return res.data;
  } catch (err) {
    console.error(
      "Erreur lors de la mise à jour du statut de la commande :",
      err
    );
    return null;
  }
};

// Delete order
export const deleteOrder = async (orderId) => {
  try {
    const res = await api.post(`/orders/delete/${orderId}`);
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la suppression de la commande :", err);
    return null;
  }
};
