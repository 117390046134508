import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import FoodListByCategory from "./FilterFoodListByCategoriesAndAllergens";
import { CategoriesList } from ".";

const MenuALaCarte = () => {
  const [foods, setFoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 400,
    });

    // Get all foods
    axios
      .get(`${process.env.REACT_APP_API_URL}/foods/all`)
      .then((response) => {
        setFoods(response.data.data);
      })
      .catch((error) => {
        console.error("Erreur fetch data: ", error);
      });

    // Get all categories
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error("Erreur fetch data: ", error);
      });
  }, []);

  // Function to group foods by category
  const groupFoodsByCategory = (foods) => {
    if (!categories) {
      return {};
    }

    const groupedFoods = {};
    foods.forEach((food) => {
      const categoryName = getCategoryName(food.food_category);
      if (!groupedFoods[categoryName]) {
        groupedFoods[categoryName] = [];
      }
      groupedFoods[categoryName].push(food);
    });
    return groupedFoods;
  };

  // Function to get the name of a category from its ID
  const getCategoryName = (categoryIds) => {
    if (!categories || categories.length === 0) {
      return "Loading categories...";
    }
    if (Array.isArray(categoryIds)) {
      // If it's an array of IDs, we'll get the name of each category
      const categoryNames = categoryIds.map((categoryId) => {
        const category = categories.find(
          (cat) => cat.category_id === categoryId
        );
        return category ? category.category_name : "Catégorie inconnue";
      });

      // Return a string with the names of the categories separated by a comma
      return categoryNames.join(", ");
    } else {
      // if it's a single ID, we'll get the name of the category
      const category = categories.find(
        (cat) => cat.category_id === categoryIds
      );
      return category ? category.category_name : "Catégorie inconnue";
    }
  };

  const handleCategorySelect = (categoryId) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  return (
    <motion.div className="w-full select-none">
      <div className="flex flex-col items-center justify-start gap-12 mb-10">
        <p
          className="text-[45px] xl:text-[72px] md:text-[70px] font-sans font-extrabold tracking-wider"
          data-aos="fade-zoom-in"
          data-aos-delay="100"
        >
          Notre{" "}
          <span
            className="text-red-600"
            data-aos="fade-zoom-in"
            data-aos-delay="150"
          >
            Menu
          </span>
        </p>
      </div>
      <CategoriesList
        handleCategorySelect={handleCategorySelect}
        selectedCategories={selectedCategories}
        categories={categories}
      />
      {/* Display food by Category */}
      {selectedCategories !== null ? (
        <FoodListByCategory
          foods={foods}
          selectedCategories={selectedCategories}
          categories={categories}
        />
      ) : null}
    </motion.div>
  );
};

export default MenuALaCarte;
