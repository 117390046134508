import React from "react";
import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
} from "recharts";

const DBSalesByFoodCategoryChart = ({ data }) => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return (
      <div className="text-center">
        {t("DBSalesByFoodCategoryChart.noData")}
      </div>
    );
  }

  // Sort data by sales and keep only the top 10 categories
  const sortedData = Object.entries(data)
    .map(([category, sales]) => ({ name: category, sales }))
    .sort((a, b) => b.sales - a.sales);
  const topTenCategories = sortedData.slice(0, 10);
  const othersSales = sortedData
    .slice(10)
    .reduce((acc, curr) => acc + curr.sales, 0);
  topTenCategories.push({ name: "Autres", sales: othersSales });

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A83731",
    "#8470FF",
    "#20B2AA",
    "#FF69B4",
    "#BA55D3",
    "#CD5C5C",
    "#90EE90",
  ];

  // Function to render a custom tooltip
  const renderCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let tooltipContent;

      if (payload[0].name === "Autres") {
        const otherPlatsCount = sortedData.slice(10).length;
        tooltipContent = (
          <>
            <p className="text-purple-600">{`Autres : ${payload[0].value} €`}</p>
            <p className="text-gray-600">{`Nombre de plats : ${otherPlatsCount}`}</p>
          </>
        );
      } else {
        tooltipContent = (
          <p className="text-purple-600">{`${payload[0].name} : ${payload[0].value} €`}</p>
        );
      }

      return (
        <div className="bg-white border border-gray-300 p-2 shadow-lg rounded-md">
          {tooltipContent}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col items-center w-full my-16">
      <h2 className="text-center mb-4 font-semibold dark:text-red-500">
        {t("DBSalesByFoodCategoryChart.title")}
      </h2>

      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={topTenCategories}
            dataKey="sales"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label
          >
            {topTenCategories.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DBSalesByFoodCategoryChart;
