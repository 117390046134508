import React, { useState, useEffect } from "react";
import DBCategoryCard from "./DBCategoryCard";
import {
  deleteCategory,
  getAllCategories,
  updateCategory,
} from "../../../api/categories/categories";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";

const DBCategories = () => {
  const [categories, setCategories] = useState([]);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesFromDB = await getAllCategories();
        setCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategory = (categoryId) => {
    deleteCategory(categoryId)
      .then(() => {
        const updatedCategories = categories.filter(
          (category) => category.category_id !== categoryId
        );
        setCategories(updatedCategories);
        notifyToast(
          "success",
          t("DBCategories.categoryDeleted"),
          "idDBCategoriesCategoryDeleted",
          true
        );
      })
      .catch((error) => {
        console.error("Error deleting category: ", error);
      });
  };

  const handleUpdateCategory = (updatedCategory) => {
    updateCategory(updatedCategory.category_id, updatedCategory)
      .then(() => {
        const updatedCategories = categories.map((category) =>
          category.category_id === updatedCategory.category_id
            ? updatedCategory
            : category
        );
        setCategories(updatedCategories);
        notifyToast(
          "success",
          t("DBCategories.categoryUpdated"),
          "idDBCategoriesCategoryUpdated",
          true
        );
      })
      .catch((error) => {
        console.error("Error updating category: ", error);
      });
  };

  // Filter categories by name
  const filteredCategories = categories.filter((category) =>
    category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBCategories.categoriesTitle")}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3"
          placeholder={t("DBCategories.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredCategories.map((category) => (
          <DBCategoryCard
            key={category.category_id}
            category={category}
            onDelete={handleDeleteCategory}
            onUpdate={handleUpdateCategory}
          />
        ))}
      </div>
    </div>
  );
};

export default DBCategories;
