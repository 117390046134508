import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { CategoriesList } from ".";
import { getAllFoods } from "../../api/foods/foods";
import { getAllCategories } from "../../api/categories/categories";
import { getAllAllergens } from "../../api/allergens/allergens";
import LoadingSpinner from "../Loaders/LoadingSpinner";
import { useTranslation } from "react-i18next";
import AllergensList from "./AllergensList";
import FilterFoodListByCategoriesAndAllergens from "./FilterFoodListByCategoriesAndAllergens";
import { getAllSupplements } from "../../api/supplements/supplements";

const Menu = () => {
  const [foods, setFoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [supplements, setSupplements] = useState([]);

  const [isLoadingFood, setIsLoadingFood] = useState(true);
  const [isLoadingSupplements, setIsLoadingSupplements] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingAllergens, setIsLoadingAllergens] = useState(true);
  const [allergens, setAllergens] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFoods, setFilteredFoods] = useState([]);
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const hasFetched = useRef(false);

  const { t } = useTranslation();
  const loadFoods = async () => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      const data = await getAllFoods();
      setFoods(data);
    } catch (error) {
      console.error("Erreur lors du chargement des aliments :", error);
    } finally {
      setIsLoadingFood(false);
    }
  };

  const loadSupplements = async () => {
    try {
      const data = await getAllSupplements();
      setSupplements(data);
    } catch (error) {
      console.error("Erreur lors du chargement des suppléments :", error);
    } finally {
      setIsLoadingSupplements(false);
    }
  };

  const loadCategories = async () => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      const data = await getAllCategories();
      setCategories(data);
    } catch (error) {
      console.error("Erreur lors du chargement des catégories :", error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const loadAllergens = async () => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      const data = await getAllAllergens();
      setAllergens(data);
    } catch (error) {
      console.error("Erreur lors du chargement des allergènes :", error);
    } finally {
      setIsLoadingAllergens(false);
    }
  };
  useEffect(() => {
    if (!hasFetched.current) {
      loadFoods();
      loadCategories();
      loadAllergens();
      loadSupplements();
      hasFetched.current = true;
    }
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 400,
    });
  }, []);

  useEffect(() => {
    // Update filteredFoods when searchTerm changes
    if (searchTerm) {
      const newFilteredFoods = filterItems(foods);
      setFilteredFoods(newFilteredFoods);
    } else {
      // If searchTerm is empty, we reset filteredFoods to foods
      setFilteredFoods(foods);
    }
  }, [searchTerm, foods]); // We need to add foods as a dependency because we use it in filterItems

  // Function to group foods by category
  const groupFoodsByCategory = (foods) => {
    if (!categories) {
      return {}; // Return an empty object or appropriate fallback
    }

    const groupedFoods = {};
    foods.forEach((food) => {
      const categoryName = getCategoryName(food.food_category);
      if (!groupedFoods[categoryName]) {
        groupedFoods[categoryName] = [];
      }
      groupedFoods[categoryName].push(food);
    });
    return groupedFoods;
  };

  // Function to get the name of a category from its ID
  const getCategoryName = (categoryIds) => {
    if (!categories || categories.length === 0) {
      return "Loading categories...";
    }
    if (Array.isArray(categoryIds)) {
      // If it's an array of IDs, we'll get the name of each category
      const categoryNames = categoryIds.map((categoryId) => {
        const category = categories.find(
          (cat) => cat.category_id === categoryId
        );
        return category ? category.category_name : "Catégorie inconnue";
      });

      // Return the names of the categories separated by a comma
      return categoryNames.join(", ");
    } else {
      // If it's a single ID, we'll get the name of the category
      const category = categories.find(
        (cat) => cat.category_id === categoryIds
      );
      return category ? category.category_name : "Catégorie inconnue";
    }
  };

  const handleCategorySelect = (categoryIds) => {
    setSelectedCategories(categoryIds);
  };

  const handleAllergenSelect = (allergenIds) => {
    setSelectedAllergens(allergenIds);
  };

  const filterItems = (items) => {
    return items.filter((item) => {
      const nameMatch = item.food_name
        ? item.food_name.toLowerCase().includes(searchTerm.toLowerCase())
        : false;
      const priceMatch = item.food_price
        ? item.food_price.toString().includes(searchTerm)
        : false;
      const categoryMatch =
        selectedCategories.length === 0 ||
        selectedCategories.some((catId) => item.food_category.includes(catId));
      const allergenMatch =
        selectedAllergens.length === 0 ||
        !selectedAllergens.some((allergenId) =>
          item.allergens.includes(allergenId)
        );

      return (nameMatch || priceMatch) && categoryMatch && allergenMatch;
    });
  };

  return (
    <motion.div className="w-full select-none">
      {isLoadingFood ||
      isLoadingCategories ||
      isLoadingAllergens ||
      isLoadingSupplements ? (
        <LoadingSpinner />
      ) : (
        // Display the menu when loading is finished
        <>
          <div className="flex flex-col items-center justify-start gap-12 mb-10">
            <p
              className="text-[35px] xl:text-[72px] md:text-[70px] font-sans font-extrabold tracking-wider dark:text-cstmParagraph-dark"
              data-aos="fade-zoom-in"
              data-aos-delay="100"
            >
              {t("Menu.our")}{" "}
              <span
                className="text-red-600"
                data-aos="fade-zoom-in"
                data-aos-delay="150"
              >
                {t("Menu.menu")}
              </span>
            </p>
          </div>
          <h2 className="filter-title dark:text-cstmParagraph-dark">
            {t("Menu.selectCategories")}
          </h2>
          <CategoriesList
            handleCategorySelect={handleCategorySelect}
            selectedCategories={selectedCategories}
            categories={categories}
          />
          <h2 className="filter-title mt-2 dark:text-cstmParagraph-dark">
            {t("Menu.excludeAllergens")}
          </h2>
          <AllergensList
            handleAllergenSelect={handleAllergenSelect}
            selectedAllergens={selectedAllergens}
            allergens={allergens}
          />
          {/* Display food by category */}
          {selectedCategories !== null ? (
            <>
              <FilterFoodListByCategoriesAndAllergens
                foods={foods}
                selectedCategories={selectedCategories}
                categories={categories}
                selectedAllergens={selectedAllergens}
                allergens={allergens}
                supplements={supplements}
              />
            </>
          ) : null}
        </>
      )}
    </motion.div>
  );
};
export default Menu;
