import React from "react";

const CloseIcon = () => {
  return (
    <svg
      className="CloseIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="0.5s"
        begin="CloseIcon.mouseover"
        end="CloseIcon.mouseout"
      />
    </svg>
  );
};

export default CloseIcon;
