const categoryReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_ALL_CATEGORIES":
      return state;

    case "SET_ALL_CATEGORIES":
      return action.foods;

    default:
      return state;
  }
};

export default categoryReducer;
