import React from "react";
import { motion } from "framer-motion";

const DBHamburgerIcon = React.forwardRef(
  ({ isMenuOpen, handleMenuClick }, ref) => {
    const lineVariants = {
      open: {
        rotate: 45,
        translateY: 10,
      },
      closed: {
        rotate: 0,
        translateY: 0,
      },
    };

    const middleLineVariants = {
      open: {
        opacity: 0,
      },
      closed: {
        opacity: 1,
      },
    };

    const bottomLineVariants = {
      open: {
        rotate: -45,
        translateY: -10,
      },
      closed: {
        rotate: 0,
        translateY: 0,
      },
    };

    const buttonVariants = {
      open: { x: 260 },
      closed: { x: 0 },
    };

    return (
      <motion.button
        ref={ref} //
        onClick={handleMenuClick}
        className="flex flex-col justify-around h-10 w-12 lg-hidden  rounded-md cursor-pointer bg-white backdrop-blur-md shadow-md border border-black p-1 z-50"
        variants={buttonVariants}
        animate={isMenuOpen ? "open" : "closed"}
      >
        <motion.div
          className="w-full h-1 bg-black rounded-xl"
          variants={lineVariants}
          animate={isMenuOpen ? "open" : "closed"}
        />
        <motion.div
          className="w-full h-1 bg-black rounded-xl"
          variants={middleLineVariants}
          animate={isMenuOpen ? "open" : "closed"}
        />
        <motion.div
          className="w-full h-1 bg-black rounded-xl"
          variants={bottomLineVariants}
          animate={isMenuOpen ? "open" : "closed"}
        />
      </motion.button>
    );
  }
);

export default DBHamburgerIcon;
