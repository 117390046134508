import { motion } from "framer-motion";
import React, { useState } from "react";
import { buttonClick, fadeInOut } from "../animations";
import { FaEye, FaEyeSlash } from "../assets/icons";

const LoginInput = ({
  placeHolder,
  icon,
  inputState,
  inputStateFunc,
  type,
  isSignUp,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  // Condition for displaying the showPassword icon only if the type is "password"
  const showPasswordIcon = type === "password";

  // Function to toggle the showPassword state
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <motion.div
      {...fadeInOut}
      className={`flex items-center justify-center gap-4 bg-cstmLoginForm dark:bg-cstmLoginForm-dark backdrop-blur-md rounded-md w-full px-4 py-2 ${
        isFocus ? "shadow-md shadow-red-700" : "shadow-none"
      }`}
    >
      {icon}
      <input
        type={showPassword ? "text" : type}
        placeholder={placeHolder}
        className="w-full h-full bg-transparent text-cstmHeadingColor dark:text-cstmHeadingColor-dark text-lg font-semibold outline-none"
        value={inputState}
        onChange={(e) => inputStateFunc(e.target.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      {/* Display FaEye only if type is password */}
      {showPasswordIcon && (
        <motion.div
          {...buttonClick}
          onClick={toggleShowPassword}
          className="cursor-pointer"
        >
          {showPassword ? (
            <FaEye className="text-xl" />
          ) : (
            <FaEyeSlash className="text-xl" />
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default LoginInput;
