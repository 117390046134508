import { t } from "i18next";
import React from "react";
import Select from "react-select";

const fixedColor = "#ff6347";

const AllergensList = ({
  handleAllergenSelect,
  selectedAllergens,
  allergens,
}) => {
  if (!allergens || allergens.length === 0) {
    return <p>Loading allergens...</p>;
  }

  const allergensOptions = allergens.map((allergen) => ({
    value: allergen.allergen_id,
    label: allergen.allergen_name,
    color: fixedColor,
  }));

  const handleChange = (selectedOptions) => {
    handleAllergenSelect(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const selectedValues = allergensOptions.filter((allergen) =>
    selectedAllergens.includes(allergen.value)
  );

  // Custom styles for react-select
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? fixedColor
          : isFocused
          ? fixedColor
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected || isFocused
          ? "white"
          : "black",
        cursor: isDisabled ? "not-allowed" : "default",
        fontWeight: "bold",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? fixedColor : null),
        },
      };
    },
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: fixedColor,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "darkblue",
        color: "white",
      },
    }),
  };

  return (
    <div>
      <Select
        options={allergensOptions}
        onChange={handleChange}
        value={selectedValues}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        styles={colourStyles}
        placeholder={t("allergensList.placeholder")}
      />
    </div>
  );
};

export default AllergensList;
