import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "../../assets";
import {
  isActiveStylesDashboard,
  isNotActiveStylesDashboard,
} from "../../utils/styles";
import {
  AiFillHome,
  IoMdPeople,
  IoMdAddCircleOutline,
  FaUtensils,
  IoIosListBox,
} from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { AiFillSetting } from "react-icons/ai";
import "./ScrollBar/DBScrollBar.css";

const DBLeftSection = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className={`h-full py-12 bg-slate-100 dark:bg-primary-dark overflow-y-scroll backdrop-blur-md shadow-md min-w-210 w-300 gap-3 border border-r-black flex-col ${
        isMenuOpen ? "block" : "hidden"
      } lg:flex`}
    >
      <NavLink
        to={"/"}
        className="flex items-center justify-center pb-6 gap-4 "
      >
        <img src={Logo} className="h-12" alt="logo"></img>
        <p className="font-semibold text-xl dark:text-white">uBento</p>
      </NavLink>
      <hr />
      <ul className="flex flex-col gap-4">
        <NavLink
          to={"/dashboard/home"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <AiFillHome className="mx-3" /> {t("dbLeftSection.home")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/orders"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoIosListBox className="mx-3" /> {t("dbLeftSection.orders")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/clients"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdPeople className="mx-3" /> {t("dbLeftSection.clients")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/food"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <FaUtensils className="mx-3" /> {t("dbLeftSection.dishes")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/categories"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <FaUtensils className="mx-3" /> {t("dbLeftSection.categories")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/supplements"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <FaUtensils className="mx-3" /> {t("dbLeftSection.supplements")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/allergens"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <FaUtensils className="mx-3" /> {t("dbLeftSection.allergens")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/sauces"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <FaUtensils className="mx-3" /> {t("dbLeftSection.sauces")}
          </div>
        </NavLink>
        <hr /> <hr />
        <NavLink
          to={"/dashboard/newFood"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 bg-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdAddCircleOutline className="mx-3" /> {t("dbLeftSection.dish")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/newSupplement"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdAddCircleOutline className="mx-3" />{" "}
            {t("dbLeftSection.supplement")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/newCategory"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdAddCircleOutline className="mx-3" />{" "}
            {t("dbLeftSection.category")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/newAllergen"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdAddCircleOutline className="mx-3" />{" "}
            {t("dbLeftSection.allergen")}
          </div>
        </NavLink>
        <NavLink
          to={"/dashboard/newSauce"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <IoMdAddCircleOutline className="mx-3" /> {t("dbLeftSection.sauce")}
          </div>
        </NavLink>
        <hr />
        <hr />
        <NavLink
          to={"/dashboard/settings"}
          className={({ isActive }) =>
            isActive
              ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
              : isNotActiveStylesDashboard
          }
        >
          <div className="flex items-center">
            <AiFillSetting className="mx-3" /> {t("dbLeftSection.settings")}
          </div>
        </NavLink>
      </ul>
      <hr />
      <hr />
      <div className="w-full items-center justify-center flex p-5 mt-auto px-2">
        <div className="w-full h-full rounded-md  justify-center items-center flex">
          <p className="font-semibold text-black dark:text-white">
            &copy; 2023 uBento
          </p>
        </div>
      </div>

      <p className="font-semibold text-black justify-center flex dark:text-white">
        Made with ❤️ by &nbsp;{" "}
        <a
          href="https://www.gregorydemeur.be"
          className="transition-colors duration-500 ease-in-out hover:text-red-500"
        >
          GDM
        </a>
      </p>
    </div>
  );
};

export default DBLeftSection;
