import { api } from "../index";

export const getMonthlySales = async () => {
  try {
    const res = await api.get(`/stats/monthly-sales`);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getOrdersPerDay = async () => {
  try {
    const res = await api.get(`/stats/orders-per-day`);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSalesByFoodName = async () => {
  try {
    const res = await api.get(`/stats/sales-by-food-name`);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSalesByFoodCategory = async () => {
  try {
    const res = await api.get(`/stats/sales-by-food-category`);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
