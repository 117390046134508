import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getMonthlySales,
  getOrdersPerDay,
  getSalesByFoodCategory,
  getSalesByFoodName,
} from "../../api/stats/stats";
import DBMonthlySalesChart from "./DBStats/DBMonthlySalesChart";
import DBOrdersPerDayChart from "./DBStats/DBOrdersPerDayChart";
import DBSalesByFoodNameChart from "./DBStats/DBSalesByFoodNameChart";
import DBStatsViewToggle from "./DBStats/DBStatsViewToggle";
import DBSalesByFoodCategoryChart from "./DBStats/DBSalesByFoodCategoryChart";

const DBHome = () => {
  const [monthlySales, setMonthlySales] = useState([]);
  const [ordersPerDay, setOrdersPerDay] = useState([]);
  const [salesByFoodName, setSalesByFoodName] = useState([]);
  const [salesByFoodCategory, setSalesByFoodCategory] = useState([]);
  const [viewType, setViewType] = useState("list");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMonthlySales = async () => {
      try {
        const monthlySalesFromDB = await getMonthlySales();
        setMonthlySales(monthlySalesFromDB);
      } catch (error) {
        console.error("Error fetching monthly sales: ", error);
      }
    };

    const fetchOrdersPerDay = async () => {
      try {
        const fetchOrdersPerDayFromDB = await getOrdersPerDay();
        setOrdersPerDay(fetchOrdersPerDayFromDB);
      } catch (error) {
        console.error("Error fetching monthly sales: ", error);
      }
    };

    const fetchSalesByFoodName = async () => {
      try {
        const fetchSalesByFoodNameFromDB = await getSalesByFoodName();
        setSalesByFoodName(fetchSalesByFoodNameFromDB);
      } catch (error) {
        console.error("Error fetching sales by food name : ", error);
      }
    };

    const fetchSalesByFoodCategory = async () => {
      try {
        const fetchSalesByCategoryFromDB = await getSalesByFoodCategory();
        setSalesByFoodCategory(fetchSalesByCategoryFromDB);
      } catch (error) {
        console.error("Error fetching sales by category : ", error);
      }
    };

    fetchMonthlySales();
    fetchOrdersPerDay();
    fetchSalesByFoodName();
    fetchSalesByFoodCategory();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBHome.dashboard")}
      </h1>
      <div className="hidden md:block">
        <DBStatsViewToggle viewType={viewType} setViewType={setViewType} />
      </div>
      <div
        className={`mt-6 ${
          viewType === "grid"
            ? "grid grid-cols-1 md:grid-cols-2 gap-6"
            : "space-y-6"
        }`}
      >
        <DBMonthlySalesChart
          data={monthlySales}
          className="bg-white shadow rounded-lg p-6"
        />
        <DBOrdersPerDayChart
          data={ordersPerDay}
          className="bg-white shadow rounded-lg p-6"
        />
        <DBSalesByFoodNameChart
          data={salesByFoodName}
          className="bg-white shadow rounded-lg p-6"
        />
        <DBSalesByFoodCategoryChart
          data={salesByFoodCategory}
          className="bg-white shadow rounded-lg p-6"
        />
      </div>
    </div>
  );
};

export default DBHome;
