import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "../../../assets";
import {
  isActiveStylesDashboard,
  isNotActiveStyles,
} from "../../../utils/styles";
import { useTranslation } from "react-i18next";
import {
  AiFillHome,
  IoMdPeople,
  IoMdAddCircleOutline,
  FaUtensils,
  IoIosListBox,
} from "../../../assets/icons";
import { AiFillSetting } from "react-icons/ai";
import { motion } from "framer-motion";
import DBHamburgerIcon from "./DBHamburgerIcon";
import "./DBHamburgerMenuStyles.css";

const DBHamburgerMenu = ({ isMenuOpen, handleMenuClick, setIsMenuOpen }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen, setIsMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        // ignore clicks on the button itself
        return;
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, setIsMenuOpen]);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="fixed lg:top-12 top-4 left-13 z-50 lg:hidden">
        <DBHamburgerIcon
          ref={buttonRef}
          isMenuOpen={isMenuOpen}
          handleMenuClick={handleMenuClick}
        />
      </div>

      {isMenuOpen && (
        <motion.div
          ref={menuRef}
          className="fixed top-0 -left-0  z-40 h-full bg-white shadow-lg w-96 menu-scrollbar"
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-center gap-4 p-4">
              <NavLink
                to={"/"}
                onClick={closeMenu}
                className="flex items-center justify-center pb-2 gap-4 "
              >
                <img src={Logo} className="h-12" alt="logo"></img>
                <p className="font-semibold text-xl">uBento</p>
              </NavLink>
            </div>

            <ul className="flex flex-col gap-4">
              <NavLink
                to={"/dashboard/home"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <AiFillHome className="mx-3" /> {t("dbLeftSection.home")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/orders"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoIosListBox className="mx-3" /> {t("dbLeftSection.orders")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/clients"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdPeople className="mx-3" /> {t("dbLeftSection.clients")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/food"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <FaUtensils className="mx-3" /> {t("dbLeftSection.dishes")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/categories"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <FaUtensils className="mx-3" />{" "}
                  {t("dbLeftSection.categories")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/supplements"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <FaUtensils className="mx-3" />{" "}
                  {t("dbLeftSection.supplements")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/allergens"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <FaUtensils className="mx-3" /> {t("dbLeftSection.allergens")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/sauces"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <FaUtensils className="mx-3" /> {t("dbLeftSection.sauces")}
                </div>
              </NavLink>
              <hr /> <hr />
              <NavLink
                to={"/dashboard/newFood"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 bg-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdAddCircleOutline className="mx-3" />{" "}
                  {t("dbLeftSection.dish")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/newSupplement"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdAddCircleOutline className="mx-3" />{" "}
                  {t("dbLeftSection.supplement")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/newCategory"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdAddCircleOutline className="mx-3" />{" "}
                  {t("dbLeftSection.category")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/newAllergen"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdAddCircleOutline className="mx-3" />{" "}
                  {t("dbLeftSection.allergen")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/newSauce"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <IoMdAddCircleOutline className="mx-3" />{" "}
                  {t("dbLeftSection.sauce")}
                </div>
              </NavLink>
              <NavLink
                to={"/dashboard/settings"}
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive
                    ? `${isActiveStylesDashboard} px-4 py-2 border-l-8 border-red-500 text-white`
                    : isNotActiveStyles
                }
              >
                <div className="flex items-center">
                  <AiFillSetting className="mx-3" />{" "}
                  {t("dbLeftSection.settings")}
                </div>
              </NavLink>
            </ul>

            <div className="w-full items-center justify-center flex pt-5 mt-auto px-2">
              <div className="w-full h-full rounded-md  justify-center items-center flex">
                <p className="font-semibold text-black">&copy; 2023 uBento</p>
              </div>
            </div>

            <p className="font-semibold text-black justify-center flex pb-6 pt-2">
              Made with ❤️ by &nbsp;{" "}
              <a
                href="https://www.gregorydemeur.be"
                className="transition-colors duration-500 ease-in-out hover:text-red-500"
              >
                GDM
              </a>
            </p>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default DBHamburgerMenu;
