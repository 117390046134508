import React, { useState, useEffect } from "react";
import DBSauceCard from "./DBSauceCard";
import {
  deleteSauce,
  getAllSauces,
  updateSauce,
} from "../../../api/sauces/sauces";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";

const DBSauces = () => {
  const [sauces, setSauces] = useState([]);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSauces = async () => {
      try {
        const saucesFromDB = await getAllSauces();
        setSauces(saucesFromDB);
      } catch (error) {
        console.error("Error fetching sauces: ", error);
      }
    };

    fetchSauces();
  }, []);

  const handleDeleteSauce = (sauceId) => {
    deleteSauce(sauceId)
      .then(() => {
        // Filter out the sauce that was deleted
        const updatedSauces = sauces.filter(
          (sauce) => sauce.sauce_id !== sauceId
        );
        setSauces(updatedSauces);
        notifyToast(
          "success",
          t("DBSauces.sauceDeleted"),
          "idDBSaucesSauceDeleted",
          true
        );
      })
      .catch((error) => {
        console.error("Error deleting sauce: ", error);
      });
  };

  const handleUpdateSauce = (updatedSauce) => {
    updateSauce(updatedSauce.sauce_id, updatedSauce)
      .then(() => {
        const updatedSauces = sauces.map((sauce) =>
          sauce.sauce_id === updatedSauce.sauce_id ? updatedSauce : sauce
        );
        setSauces(updatedSauces);
        notifyToast(
          "success",
          t("DBSauces.sauceUpdated"),
          "idDBSaucesSauceUpdated",
          true
        );
      })
      .catch((error) => {
        console.error("Error updating sauce: ", error);
      });
  };

  // Filter sauces by name
  const filteredSauces = sauces.filter((sauce) =>
    sauce.sauce_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBSauces.saucesTitle")}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3"
          placeholder={t("DBSauces.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredSauces.map((sauce) => (
          <DBSauceCard
            key={sauce.sauce_id}
            sauce={sauce}
            onDelete={handleDeleteSauce}
            onUpdate={handleUpdateSauce}
          />
        ))}
      </div>
    </div>
  );
};

export default DBSauces;
