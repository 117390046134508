import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAngleUp,
  FaCalendarAlt,
  FaListAlt,
  FaEye,
  FaUtensils,
  FaEuroSign,
  FaPlus,
} from "react-icons/fa";
import Collapse from "react-collapse";
import QRCode from "react-qr-code";
import { handleBorderStatus } from "../../Handlers/HandleColorStatus";
import moment from "moment";
import "moment/locale/ja";
import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/en-gb";
import "moment/locale/fr";
import { Modal } from "react-responsive-modal";
import { motion } from "framer-motion";
import { collapse } from "../../../animations";

const DBClientCard = ({ clientData }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMoreInfoClick = (order) => {
    setSelectedOrder(order);
    setOrderDetails(order);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
    setTimeout(() => setOrderDetails(null), 150);
  };

  return (
    <>
      <div className="bg-white dark:bg-slate-100 shadow-lg rounded-lg p-4 mb-6 mx-auto max-w-2xl lg:max-w-3xl">
        <div className="flex justify-between items-center py-3">
          <h2 className="text-2xl font-bold text-gray-800">
            {clientData.userName}
          </h2>
          {clientData.orders.length > 0 ? (
            <button
              onClick={toggleCollapse}
              className="text-red-500 hover:text-red-600 transition duration-300 flex items-center text-3xl"
              aria-label={t("DBClientCard.toggleOrders")}
            >
              {isCollapsed ? <FaAngleUp /> : <FaListAlt />}
            </button>
          ) : (
            <></>
          )}
        </div>

        {clientData.orders.length > 0 && (
          <Collapse isOpened={isCollapsed}>
            {clientData.orders.map((order, index) => (
              <motion.div
                key={index}
                initial="closed"
                animate={isCollapsed ? "open" : "closed"}
                variants={collapse}
                transition={{ duration: 0.4 }}
              >
                <div
                  key={index}
                  className="border-t py-3 hover:bg-gray-300 cursor-pointer relative hover:border hover:rounded-lg"
                  onClick={() => handleMoreInfoClick(order)}
                >
                  <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100">
                    <FaEye className="text-3xl" />
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex flex-col ">
                      <span className="text-lg flex items-center mb-2">
                        <FaCalendarAlt className="text-red-500 mr-1" />
                        {moment(order.date).format("LLL")}
                      </span>
                      <QRCode
                        value={order.orderNumber}
                        size={100}
                        level={"H"}
                        className="ml-3"
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center text-lg">
                    </div>
                    <div className="flex flex-col justify-center items-end">
                      <span
                        className={`badge p-2 rounded-lg ${handleBorderStatus(
                          order.statut
                        )}`}
                      >
                        {order.statut}
                      </span>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </Collapse>
        )}
      </div>

      {/* Order details modal */}
      <Modal open={!!selectedOrder} onClose={handleCloseModal} center>
        {orderDetails && (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">
              {t("DBClientCard.orderDetails")}
            </h2>
            <div className="flex justify-center mb-4">
              <QRCode value={orderDetails.orderNumber} size={150} level={"H"} />
            </div>
            <h3 className="text-lg font-semibold">
              {t("DBClientCard.foodOrdered")}
            </h3>
            <ul className="list-disc pl-5 mb-4">
              {orderDetails.items.map((item, index) => (
                <li key={index} className="mb-1 my-2">
                  <span className="font-medium">{item.food_name}</span> -{" "}
                  {item.food_price / item.food_quantity}€ x {item.food_quantity}
                  {item.food_supplements &&
                    item.food_supplements.length > 0 && (
                      <ul className="ml-6 mt-1 ">
                        {item.food_supplements.map((supplement, suppIndex) => (
                          <li key={suppIndex} className="flex items-center ">
                            <FaPlus className="text-green-500 mr-2" />
                            {supplement.supplement_name}
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
            <p className="font-semibold">
              <FaUtensils className="inline mr-2" />
              {t("DBClientCard.sauce")}: {orderDetails.sauce}
            </p>
            <p className="font-semibold">
              <FaEuroSign className="inline mr-2" />
              {t("DBClientCard.total")}: {orderDetails.orderTotal}€
            </p>
            <p className="mt-4">
              <span
                className={`badge p-2 rounded-lg ${handleBorderStatus(
                  orderDetails.statut
                )}`}
              >
                {t("DBClientCard.status")}: {orderDetails.statut}
              </span>
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default DBClientCard;
