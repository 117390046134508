import React, { useState, useEffect } from "react";
import DBClientCard from "./DBClientCard";
import { getAllUsersWithOrders } from "../../../api/users/users";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import "./DBClients.css";

const CLIENTS_PER_PAGE = 50;

const DBClients = () => {
  const [clientsData, setClientsData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const [clientsPerPage, setClientsPerPage] = useState(CLIENTS_PER_PAGE);
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    const fetchClientsData = async () => {
      const response = await getAllUsersWithOrders();
      if (response) {
        setClientsData(response.users);
      }
    };

    fetchClientsData();
  }, []);

  useEffect(() => {
    const allClients = Object.entries(clientsData).filter(([userId, client]) =>
      client.userName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedClients = allClients.slice(
      currentPage * clientsPerPage,
      (currentPage + 1) * clientsPerPage
    );

    setFilteredClients(paginatedClients);
  }, [clientsData, currentPage, clientsPerPage, searchTerm]);

  // Handle the search and reset the page
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reinicialize the pagination to the first page when searching
    console.log(clientsData);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleClientsPerPageChange = (event) => {
    setClientsPerPage(Number(event.target.value));
    setCurrentPage(0); // Reinitialize the pagination to the first page when changing the number of clients per page
  };

  const allClientsLength = Object.entries(clientsData).filter(
    ([userId, client]) =>
      client.userName.toLowerCase().includes(searchTerm.toLowerCase())
  ).length;

  // Calculate the number of pages
  const pageCount = Math.ceil(allClientsLength / clientsPerPage);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6 my-6 text-center dark:text-red-600">
        {t("DBClients.title")}
      </h1>
      <div className="mb-4 mx-auto max-w-2xl lg:max-w-3xl">
        <input
          type="text"
          className="shadow border rounded py-4 px-3 mb-6 lg:mb-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:w-[70%] w-full text-xl"
          placeholder={t("DBClients.searchPlaceholder")}
          value={searchTerm}
          onChange={handleSearch}
        />
        <select
          value={clientsPerPage}
          onChange={handleClientsPerPageChange}
          className="shadow border rounded py-2 lg:ml-2 px-3   text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:w-[28%] w-full text-xl"
        >
          {[5, 10, 20, 30, 40, 50, 100, 200, 1000].map((number) => (
            <option key={number} value={number}>
              {number} {t("DBClients.perPage")}
            </option>
          ))}
        </select>
      </div>
      {filteredClients.map(([userId, clientData]) => (
        <DBClientCard key={userId} clientData={clientData} />
      ))}
      <ReactPaginate
        previousLabel={t("DBClients.previousPage")}
        nextLabel={t("DBClients.nextPage")}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default DBClients;
