import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { getRestaurantInfos } from "../../api/settings/settings";
import CustomToastContainer from "../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import RestaurantSchedule from "./RestaurantSchedule";

const RestaurantInfos = () => {
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [restaurantInfo, setRestaurantInfo] = useState(null);

  useEffect(() => {
    const fetchRestaurantInfos = async () => {
      try {
        const response = await getRestaurantInfos();
        if (response) {
          setRestaurantInfo(response);
        } else {
          notifyToast("error", t("RestaurantInfos.errorFetchingSettings"));
        }
      } catch (error) {
        notifyToast(
          "error",
          t("RestaurantInfos.errorFetchingSettings"),
          "idErrorFetchingRestaurantInfosSettings"
        );
      }
    };
    fetchRestaurantInfos();
  }, []);

  let latitude, longitude, address, contactInfos;
  if (restaurantInfo) {
    ({ latitude, longitude, address, contactInfos } = restaurantInfo);
  }
  let phoneNumber, email;
  if (contactInfos) {
    ({ phoneNumber, email } = contactInfos);
  }

  let scheduleDays;
  if (restaurantInfo && restaurantInfo.scheduleDays) {
    scheduleDays = restaurantInfo.scheduleDays;
  }

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  const hasContactInfo = address || phoneNumber || email;
  const isLocationAvailable = latitude !== null && longitude !== null;
  return (
    <div className="mx-auto p-6 lg:mt-24 mt-14">
      <h1 className="text-3xl font-bold text-center mb-12 dark:text-red-600 ">
        {t("RestaurantInfos.title")}
      </h1>

      <div className="lg:grid lg:grid-cols-2 lg:gap-10">
        <div className="p-6 mb-6 xl:mb-6">
          <h2 className="text-2xl font-semibold mb-6 text-center dark:text-blue-600 text-red-600">
            {t("RestaurantInfos.contact")}
          </h2>

          {hasContactInfo ? (
            <>
              {address && (
                <a
                  href={googleMapsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mb-4 dark:hover:text-blue-400 dark:text-cstmParagraph-dark hover:text-red-500"
                >
                  <FaMapMarkerAlt className="mr-3 text-xl " />
                  <p className="text-lg ">{address}</p>
                </a>
              )}
              {phoneNumber && (
                <a
                  href={`tel:${phoneNumber}`}
                  className="flex items-center mb-4 dark:hover:text-blue-400 dark:text-cstmParagraph-dark hover:text-red-500"
                >
                  <FaPhone className="mr-3 text-xl" />
                  <p className="text-lg">{phoneNumber}</p>
                </a>
              )}
              {email && (
                <a
                  href={`mailto:${email}`}
                  className="flex items-center mb-4 dark:hover:text-blue-400 dark:text-cstmParagraph-dark hover:text-red-500"
                >
                  <FaEnvelope className="mr-3 text-xl" />
                  <p className="text-lg">{email}</p>
                </a>
              )}
              {scheduleDays && (
                <div className="restaurant-schedule mt-6">
                  <h2 className="text-2xl font-semibold text-center mb-6 dark:text-blue-600 text-red-600">
                    {t("RestaurantInfos.schedule")}
                  </h2>
                  <RestaurantSchedule scheduleDays={scheduleDays} />
                </div>
              )}
            </>
          ) : (
            <p className="text-lg text-center dark:text-white">
              {t("RestaurantInfos.noContactInfo")}
            </p>
          )}
        </div>

        {isLocationAvailable ? (
          <div className="bg-white shadow-lg rounded-lg h-96 lg:h-[400px]">
            <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}>
              <GoogleMap
                mapContainerClassName="w-full h-full rounded-lg"
                center={{ lat: latitude, lng: longitude }}
                zoom={16}
              >
                <Marker position={{ lat: latitude, lng: longitude }} />
              </GoogleMap>
            </LoadScriptNext>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-lg text-gray-700">
              {t("RestaurantInfos.mapUnavailable")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantInfos;
