import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStripe } from "@stripe/react-stripe-js";
import { createPaymentSession } from "../../api/stripePayment/stripePayment";
import { createOrder } from "../../api/orders/orders";
const StripePayment = ({
  cartItems,
  user,
  onCloseCartModal,
  disabled,
  selectedSauce,
  selectedDate,
}) => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handlePayment = async () => {
    if (isSubmitLoading) return;

    setIsSubmitLoading(true);
    try {
      const order = {
        userId: user.uid,
        userName: user.name,
        sauce: selectedSauce,
        date: selectedDate,
        statut: "En attente",
        items: cartItems.map((item) => ({
          food_name: item.food.food_name,
          food_id: item.food.food_id,
          food_price: item.food.food_price * item.quantity,
          food_quantity: item.quantity,
          food_supplements: item.supplements,
          food_imageURL: item.food.food_imageURL,
        })),
        orderTotal: cartItems.reduce(
          (total, item) => total + item.quantity * item.food.food_price,
          0
        ),
      };

      const orderResponse = await createOrder(order, user.uid);
      if (orderResponse && orderResponse.success) {
        const orderNumber = orderResponse.data.orderNumber;
        console.log("orderNumber dans stripe payement = " + orderNumber);
        const session = await createPaymentSession(order, orderNumber);
        if (session && session.sessionId) {
          stripe.redirectToCheckout({ sessionId: session.sessionId });
        } else {
          // Handle errors
        }
      }
    } catch (error) {
      // Handle errors
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const buttonClassDisabled = disabled
    ? "bg-gray-300 text-gray-500  px-6 py-3 rounded mt-4 shadow-none"
    : "bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition duration-300 ease-in-out mt-4 shadow-lg";

  return (
    <div>
      <button
        onClick={handlePayment}
        disabled={isSubmitLoading || disabled}
        className={buttonClassDisabled}
      >
        {isSubmitLoading ? t("cart.orderProcessing") : t("cart.order")}
      </button>
    </div>
  );
};

export default StripePayment;
