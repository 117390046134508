import React from "react";
import Lottie from "lottie-react";
import bentoLoader from "../assets/svg/bentoLoader.json";
import "../assets/css/Loader.css";

const MainLoader = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Lottie
        animationData={bentoLoader}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default MainLoader;
