import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const DBOrdersFilter = ({
  statusOptions,
  timeOptions,
  priceOptions,
  handleFilterChange,
  currentFilters,
}) => {
  const { t } = useTranslation();

  const customStyles = {
    // Can add custom styles here
  };

  return (
    <div className="orders-filter w-44 mt-16">
      <Select
        value={statusOptions.find(
          (option) => option.value === currentFilters.status
        )}
        options={statusOptions}
        onChange={(selected) => handleFilterChange("status", selected)}
        styles={customStyles}
        placeholder={t("DBOrdersFilter.statusPlaceholder")}
        isClearable
        className="mb-2"
      />
      <Select
        value={timeOptions.find(
          (option) => option.value === currentFilters.time
        )}
        options={timeOptions}
        onChange={(selected) => handleFilterChange("time", selected)}
        styles={customStyles}
        placeholder={t("DBOrdersFilter.hourPlaceholder")}
        isClearable
        className="mb-2"
      />
      <Select
        value={priceOptions.find(
          (option) => option.value === currentFilters.price
        )}
        options={priceOptions}
        onChange={(selected) => handleFilterChange("price", selected)}
        styles={customStyles}
        placeholder={t("DBOrdersFilter.pricePlaceholder")}
        isClearable
        className="mb-2"
      />
    </div>
  );
};

export default DBOrdersFilter;
