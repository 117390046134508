import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import InputValueField from "../InputValueField";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { getSettings, updateSettings } from "../../../api/settings/settings";
import Spinner from "../Spinner";
import BTNSave from "../../Buttons/BTNSave";
import DBDayScheduleInput from "./DBDayScheduleInput";
import DBOffDaysCalendar from "./DBOffDaysCalendar";
import DBNotifications from "./DBNotifications";
import Autocomplete from "react-google-autocomplete";
const DBSettings = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { notifyToast } = CustomToastContainer();
  const [settings, setSettings] = useState(null);
  const [addressInfo, setAddressInfo] = useState({
    address: "",
    latitude: "",
    longitude: "",
  });
  const [timeSlot, setTimeSlot] = useState("");
  const [maxOrdersPerSlot, setMaxOrdersPerSlot] = useState("");
  const [advanceBookingDays, setAdvanceBookingDays] = useState("");
  const [offDays, setOffDays] = useState([]);
  const [discordNotifications, setDiscordNotifications] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [weekSchedule, setWeekSchedule] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  const orderedDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();

        if (data.schedule) {
          setTimeSlot(data.schedule.timeSlot || "");
          setMaxOrdersPerSlot(data.schedule.maxOrdersPerSlot || "");
          setAdvanceBookingDays(data.schedule.advanceBookingDays || "");
          setOffDays(data.schedule.offDays || []);
          setWeekSchedule(data.schedule.days || {});
        }

        if (data.notifications) {
          setDiscordNotifications(data.notifications.discord || false);
        }

        if (data.address) {
          setAddressInfo({
            address: data.address.address || "",
            latitude: data.address.latitude || "",
            longitude: data.address.longitude || "",
          });
        } else {
          setAddressInfo({ address: "", latitude: "", longitude: "" });
        }

        if (data.contactInfos) {
          setEmail(data.contactInfos.email || "");
          setPhoneNumber(data.contactInfos.phoneNumber || "");
        }

        setSettings(data);
      } catch (error) {
        notifyToast("error", t("dbSettings.errorFetchingSettings") + error);
      }
    };
    fetchSettings();
  }, []);

  const handleTimeSlotChange = (value) => {
    setTimeSlot(value);
    console.log(timeSlot);
  };

  const handlePlaceSelected = (place) => {
    const newAddress = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    setAddressInfo({ address: newAddress, latitude: lat, longitude: lng });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedSettings = {
        ...settings,
        schedule: {
          ...settings?.schedule,
          timeSlot,
          maxOrdersPerSlot,
          advanceBookingDays,
          offDays,
          days: weekSchedule,
        },
        notifications: {
          discord: discordNotifications,
        },
        address: addressInfo,
        contactInfos: {
          email,
          phoneNumber,
        },
      };
      await updateSettings(updatedSettings);
      notifyToast("success", t("dbSettings.settingsUpdatedSuccess"));
    } catch (error) {
      notifyToast("error", t("dbSettings.errorUpdatingSettings"));
    }
  };

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600 text-center">
        {t("dbSettings.title")}
      </h1>
      {!settings && <Spinner />}
      {settings && (
        <form
          onSubmit={handleSubmit}
          className="border bg-white rounded-lg dark:bg-slate-100 p-4 w-full flex flex-col items-center justify-center gap-4"
        >
          <div className="flex items-center w-full mb-4">
            <label className="w-1/5 text-sm font-medium text-gray-700  mr-3 text-right">
              {t("dbSettings.addressLabel")}
            </label>
            <Autocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API}
              onPlaceSelected={handlePlaceSelected}
              options={{
                types: ["address"],
              }}
              placeholder={t("dbSettings.addressPlaceholder")}
              className="w-full px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
              language={currentLang}
              defaultValue={addressInfo.address}
            />
          </div>
          <InputValueField
            label={t("dbSettings.email")}
            type="email"
            placeHolder={t("dbSettings.enterEmail")}
            stateValue={email}
            stateFunction={setEmail}
          />

          <InputValueField
            label={t("dbSettings.phoneNumber")}
            type="tel"
            placeHolder={t("dbSettings.enterPhoneNumber")}
            stateValue={phoneNumber}
            stateFunction={setPhoneNumber}
          />
          <InputValueField
            label={t("dbSettings.timeSlot")}
            type="number"
            placeHolder={t("dbSettings.timeSlot")}
            stateValue={timeSlot}
            stateFunction={handleTimeSlotChange}
            min="1"
          />

          <InputValueField
            label={t("dbSettings.maxOrdersPerSlot")}
            type="number"
            placeHolder={t("dbSettings.maxOrdersPerSlot")}
            stateValue={maxOrdersPerSlot}
            stateFunction={setMaxOrdersPerSlot}
            min="1"
          />

          <InputValueField
            label={t("dbSettings.advanceBookingDays")}
            type="number"
            placeHolder={t("dbSettings.advanceBookingDays")}
            stateValue={advanceBookingDays}
            stateFunction={setAdvanceBookingDays}
            min="0"
          />
          <hr />
          <p>{t("dbSettings.offDays")}</p>
          <DBOffDaysCalendar offDays={offDays} setOffDays={setOffDays} />
          <hr />
          {orderedDays.map((day) => (
            <DBDayScheduleInput
              key={day}
              day={day}
              schedule={weekSchedule[day]}
              setSchedule={(newSchedule) =>
                setWeekSchedule({ ...weekSchedule, [day]: newSchedule })
              }
            />
          ))}

          <br />

          <DBNotifications
            discordNotifications={discordNotifications}
            setDiscordNotifications={setDiscordNotifications}
          />

          <BTNSave type="submit">{t("dbSettings.saveChanges")}</BTNSave>
        </form>
      )}
    </div>
  );
};

export default DBSettings;
