import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getAvailableSlots } from "../api/reservationsSlots/reservationsSlots";
import moment from "moment";
import { FaHourglassEnd } from "react-icons/fa";

const SlotReservationPicker = ({ onSlotChange }) => {
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinutes, setSelectedMinutes] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [hoursForSelectedDate, setHoursForSelectedDate] = useState([]);
  const [minutesForSelectedHour, setMinutesForSelectedHour] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchAvailableSlots();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("Time zone du navigateur:", userTimeZone);
  }, [selectedDate, selectedHour]);

  const fetchAvailableSlots = async () => {
    const slotsFromApi = await getAvailableSlots();
    setAvailableSlots(slotsFromApi);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(i18n.language, { dateStyle: "full" }).format(
      date
    );
  };

  const formatHour = (hour) => {
    const date = new Date();
    date.setHours(hour);
    return new Intl.DateTimeFormat(i18n.language, { hour: "numeric" }).format(
      date
    );
  };

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    if (dateValue === "") {
      setSelectedDate(null);
      setSelectedHour(null);
      setSelectedMinutes(null);
      setSelectedSlot(null);
      setHoursForSelectedDate([]);
      setMinutesForSelectedHour([]);
      return;
    }
    const date = new Date(dateValue);
    setSelectedDate(date);
    setSelectedHour(null);
    setSelectedMinutes(null);
    setSelectedSlot(null);
    const hours = [
      ...new Set(
        availableSlots
          .filter(
            (slot) =>
              new Date(slot.start).toDateString() === date.toDateString()
          )
          .map((slot) => new Date(slot.start).getHours())
      ),
    ];
    setHoursForSelectedDate(hours);
  };

  const handleHourChange = (event) => {
    const hour = event.target.value;
    setSelectedHour(hour);
    setSelectedMinutes(null);
    setSelectedSlot(null);
    const minutes = availableSlots
      .filter(
        (slot) =>
          new Date(slot.start).toDateString() === selectedDate.toDateString() &&
          new Date(slot.start).getHours().toString() === hour
      )
      .map((slot) => new Date(slot.start).getMinutes());
    setMinutesForSelectedHour(minutes);
  };

  const handleMinutesChange = (event) => {
    const minutes = event.target.value;
    setSelectedMinutes(minutes);
    setSelectedSlot(null);

    // Using moment js to construct a date object with the selected date, hour and minutes
    const selectedMoment = moment(selectedDate)
      .set("hour", selectedHour)
      .set("minute", minutes);

    const slot = availableSlots.find(
      (slot) =>
        moment(slot.start).isSameOrBefore(selectedMoment) &&
        moment(slot.end).isAfter(selectedMoment)
    );

    if (slot) {
      setSelectedSlot(slot);
      onSlotChange(slot.start);
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: "full",
      timeStyle: "short",
    }).format(date);
  };

  const uniqueDates = [
    ...new Set(
      availableSlots.map((slot) => new Date(slot.start).toDateString())
    ),
  ];

  return (
    <div>
      <select
        onChange={handleDateChange}
        className="w-full px-4 py-3 mt-4 shadow-md outline-none rounded-md border focus:border-red-400"
      >
        <option value="">{t("slotReservationPicker.selectDate")}</option>
        {uniqueDates.map((date) => (
          <option key={date} value={date}>
            {formatDate(date)}
          </option>
        ))}
      </select>

      {selectedDate && (
        <div className="flex gap-4 mt-4">
          <select
            onChange={handleHourChange}
            className="w-full px-4 py-3 mt-4 shadow-md outline-none rounded-md border focus:border-red-400"
          >
            <option value="">{t("slotReservationPicker.hour")}</option>
            {hoursForSelectedDate.map((hour) => (
              <option key={hour} value={hour}>
                {formatHour(hour)}
              </option>
            ))}
          </select>

          {selectedHour && (
            <select
              onChange={handleMinutesChange}
              className="w-full px-4 py-3 mt-4 shadow-md outline-none rounded-md border focus:border-red-400"
            >
              <option value="">{t("slotReservationPicker.minutes")}</option>
              {minutesForSelectedHour.map((minutes, index) => (
                <option key={index} value={minutes}>
                  {minutes.toString().padStart(2, "0")}
                </option>
              ))}
            </select>
          )}
        </div>
      )}

      {selectedSlot && (
        <div className="w-full px-4 py-3 mt-4 justify-center text-center">
          <p className="font-semibold text-lg">
            {t("slotReservationPicker.orderDate")}{" "}
            {formatDateTime(selectedSlot.start)}{" "}
          </p>
        </div>
      )}

      {!selectedSlot && selectedHour && !selectedMinutes && (
        <div className="text-center p-4 border mt-8 border-yellow-500 bg-yellow-100 rounded-md">
          <FaHourglassEnd className="text-yellow-500 mx-auto" />
          <p>{t("slotReservationPicker.completeHourPrompt")}</p>
        </div>
      )}

      {!selectedSlot && selectedHour && selectedMinutes && (
        <div className="text-center p-4 mt-8 border border-red-500 rounded-md">
          <FaHourglassEnd className="text-red-500 mx-auto" />
          <p>{t("slotReservationPicker.noAvailableSlots")}</p>
        </div>
      )}
    </div>
  );
};

export default SlotReservationPicker;
