export const buttonClick = {
  whileTap: { scale: 0.8 },
};

export const fadeInOut = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const menuAnimation = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 30 },
};

export const toggleDarkLightModeAnimation = {
  sun: {
    opacity: 1,
    rotate: 180,
    transition: {
      duration: 0.5,
    },
  },
  moon: {
    opacity: 1,
    rotate: 360,
    transition: {
      duration: 0.3,
    },
  },
};

export const perspective = {
  initial: { perspective: 1000, rotateX: 90 },
  enter: { perspective: 1000, rotateX: 0 },
  exit: { perspective: 1000, rotateX: -90 }
}

export const collapse = {
  open: { opacity: 1, height: 'auto' },
  closed: { opacity: 0, height: 0 }
}
