import React, { useState, useEffect } from "react";
import DBFoodCard from "./DBFoodCard";
import {
  deleteFood,
  getFullFoodsDetails,
  updateFood,
} from "../../../api/foods/foods";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";
import { getAllCategories } from "../../../api/categories/categories";
import { getAllAllergens } from "../../../api/allergens/allergens";
import { getAllSupplements } from "../../../api/supplements/supplements";
const DBFood = () => {
  const [foods, setFoods] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [supplementOptions, setSupplementOptions] = useState([]);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getFullFoodsDetails()
      .then((foods) => {
        setFoods(foods);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });

    getAllCategories().then((categories) => {
      setCategoryOptions(
        categories.map((cat) => ({
          value: cat.category_id,
          label: cat.category_name,
        }))
      );
    });
    getAllAllergens().then((allergens) => {
      setAllergenOptions(
        allergens.map((allergen) => ({
          value: allergen.allergen_id,
          label: allergen.allergen_name,
        }))
      );
    });
    getAllSupplements().then((supplements) => {
      setSupplementOptions(
        supplements.map((supplement) => ({
          value: supplement.supplement_id,
          label: supplement.supplement_name,
        }))
      );
    });
  }, []);

  const handleDeleteFood = (foodId) => {
    deleteFood(foodId)
      .then(() => {
        // Filter out the deleted food and update the state
        const updatedFoods = foods.filter((food) => food.food_id !== foodId);
        setFoods(updatedFoods);
        notifyToast(
          "success",
          t("DBFood.foodDeleted"),
          "idDBFoodFoodDeleted",
          true
        );
      })
      .catch((error) => {
        console.error("Error deleting food: ", error);
      });
  };

  const handleUpdateFood = (updatedFood) => {
    updateFood(updatedFood.food_id, updatedFood)
      .then((response) => {
        const newFoods = foods.map((food) =>
          food.food_id === response.food_id ? { ...response } : { ...food }
        );
        setFoods(newFoods);
        notifyToast(
          "success",
          t("DBFood.foodUpdated"),
          "idDBFoodFoodUpdated",
          true
        );
      })
      .catch((error) => {
        console.error("Error updating food: ", error);
      });
  };

  const filteredFoods = foods.filter((food) =>
    food.food_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBFood.foodTitle")}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3"
          placeholder={t("DBFood.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-4">
        {filteredFoods.map((food) => (
          <DBFoodCard
            key={food.food_id}
            food={food}
            onDelete={handleDeleteFood}
            onUpdate={handleUpdateFood}
            allergenOptions={allergenOptions}
            categoryOptions={categoryOptions}
            supplementOptions={supplementOptions}
          />
        ))}
      </div>
    </div>
  );
};

export default DBFood;
