import { api } from "../index";

// Get all settings
export const getSettings = async () => {
  try {
    const res = await api.get(`/settings/all`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update Settings
export const updateSettings = async (data) => {
  try {
    const res = await api.put(`/settings/update`, data);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Get notification settings only
export const getNotificationsSettings = async () => {
  try {
    const res = await api.get(`/settings/notifications`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get restaurant infos
export const getRestaurantInfos = async () => {
  try {
    const res = await api.get(`/settings/restaurantInfos`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
