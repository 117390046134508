import React from "react";
import Select from "react-select";
import { t } from "i18next";

const fixedColor = '#007bff';

const CategoriesList = ({
  handleCategorySelect,
  selectedCategories,
  categories,
}) => {
  if (!categories || categories.length === 0) {
    return <p>Loading categories...</p>;
  }

  const categoriesOptions = categories.map(cat => ({
    value: cat.category_id,
    label: cat.category_name,
    color: fixedColor, 
  }));

  const handleChange = (selectedOptions) => {
    handleCategorySelect(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const selectedValues = categoriesOptions.filter(cat =>
    selectedCategories.includes(cat.value)
  );

  // Custom styles for react-select
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? fixedColor
          : isFocused
          ? fixedColor
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected || isFocused
          ? 'white'
          : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: 'bold',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? fixedColor : null),
        },
      };
    },
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: fixedColor,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: 'darkblue',
        color: 'white',
      },
    }),
  };


  return (
    <div>
      <Select
        options={categoriesOptions}
        onChange={handleChange}
        value={selectedValues}
        isMulti 
        className="basic-multi-select"
        classNamePrefix="select"
        styles={colourStyles}
        placeholder={t('categoriesList.filterByCategory')}
        data-tip={t('categoriesList.filterByCategoryTooltip')}
      />
    </div>
  );
};

export default CategoriesList;
