import React from "react";
import { motion } from "framer-motion";
import DBOrderCard from "./DBOrderCard";
import { perspective } from "../../../animations";

const DBOrdersList = ({ orders, viewType, updateOrderStatus, deleteOrder }) => {
  const renderOrderCard = (order, index) => (
    <motion.div
      key={order.orderNumber + "-" + index}
      variants={perspective}
      initial="initial"
      animate="enter"
      exit="exit"
      transition={{ duration: 0.3 }}
    >
      <DBOrderCard
        order={order}
        updateOrderStatus={updateOrderStatus}
        deleteOrder={deleteOrder}
      />
    </motion.div>
  );

  const ListView = () => (
    <div className="space-y-4 mb-11">{orders.map(renderOrderCard)}</div>
  );

  const GridView = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-11">
      {orders.map(renderOrderCard)}
    </div>
  );

  return viewType === "grid" ? <GridView /> : <ListView />;
};

export default DBOrdersList;
