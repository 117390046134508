import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const DBMonthlySalesChart = ({ data }) => {
  const { t } = useTranslation();

  // Extract unique years from data
  const uniqueYears = Array.from(
    new Set(Object.keys(data).map((key) => key.split("-")[1]))
  );

  // State for the selected year
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Use effect to set the selected year to the current year if it exists in the data
  useEffect(() => {
    const uniqueYears = Array.from(
      new Set(Object.keys(data).map((key) => key.split("-")[1]))
    );
    const currentYear = new Date().getFullYear().toString();
    if (uniqueYears.includes(currentYear)) {
      setSelectedYear(currentYear);
    } else if (uniqueYears.length > 0) {
      setSelectedYear(uniqueYears[0]);
    }
  }, [data]);

  // Use effect to filter the data when the selected year changes
  useEffect(() => {
    if (selectedYear) {
      const filtered = Object.entries(data)
        .filter(([monthYear]) => monthYear.endsWith(selectedYear))
        .map(([month, sales]) => ({ month, sales }));
      setFilteredData(filtered);
    }
  }, [selectedYear, data]);

  return (
    <div className="flex flex-col items-center w-full my-16">
      <h2 className="text-center mb-4 font-semibold dark:text-red-500">
        {t("DBMonthlySalesChart.title")}
      </h2>
      <select
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        className="mb-4 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      >
        {uniqueYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={filteredData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" name={t("DBMonthlySalesChart.month")} />
          <YAxis
            label={{
              value: t("DBMonthlySalesChart.sales"),
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip
            formatter={(value) => [
              `${value} € `,
              t("DBMonthlySalesChart.sales"),
            ]}
          />
          <Legend />
          <Bar
            dataKey="sales"
            name={t("DBMonthlySalesChart.sales")}
            fill="#8884d8"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DBMonthlySalesChart;
