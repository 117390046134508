import React, { useEffect, useState } from "react";
import { Logo } from "../assets";
import { NavLink, useNavigate } from "react-router-dom";
import { isNotActiveStyles, isActiveStyles } from "../utils/styles";
import { motion } from "framer-motion";
import { buttonClick, menuAnimation } from "../animations";
import { MdLogout, SiBento } from "../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { app } from "../config/firebase.config";
import { setUserNULL } from "../context/actions/userActions";
import Cart from "./Cart";
import Modal from "react-responsive-modal";
import { addToCart, removeFromCart } from "../context/actions/cartActions";
import { getItemIdentifier } from "../context/reducers/cartReducer";
import default_avatar from "../assets/img/default_avatar.png";
import { useTranslation } from "react-i18next";
import LanguageDropdownList from "./Header/LanguageDropdownList";
import { changeLanguage } from "i18next";
import DarkLightModeToggle from "./Header/DarkLightModeToggle";
const Header = ({ isAdmin }) => {
  const user = useSelector((state) => state.user);
  const [isMenu, setIsMenu] = useState(false);
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCart, setOpenCart] = useState(false);
  const onOpenCartModal = () => setOpenCart(true);
  const onCloseCartModal = () => setOpenCart(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true";
  });
  const { t } = useTranslation();
  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, []);
  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem("darkMode", newMode);
    document.documentElement.classList.toggle("dark", newMode);
  };

  const cartItems = useSelector((state) => state.cart.items);
  const signOut = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        dispatch(setUserNULL());
        navigate("/login", { replace: true });
      })
      .catch((err) => console.log(err));
  };

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const removeItemFromCart = (itemIdentifier) => {
    dispatch(removeFromCart(itemIdentifier));
  };

  const handleAddToCart = (item) => {
    const itemExists = cartItems.some(
      (existingItem) =>
        getItemIdentifier(existingItem) === getItemIdentifier(item)
    );

    if (itemExists) {
      const updatedItems = cartItems.map((existingItem) =>
        getItemIdentifier(existingItem) === getItemIdentifier(item)
          ? { ...existingItem, quantity: existingItem.quantity + 1 }
          : existingItem
      );

      dispatch(addToCart(updatedItems));
    } else {
      dispatch(addToCart([...cartItems, item]));
    }
  };

  const closeMenu = () => {
    setIsMenu(false);
  };

  return (
    <header className="fixed backdrop-blur-md z-50 inset-x-4 top-0 flex items-center justify-between px-4 md:px-20 py-6 ">
      <NavLink to={"/"} className="flex items-center justify-center gap-4">
        <img src={Logo} className="h-16" alt="logo"></img>
        <p className="font-semibold text-xl hidden md:block dark:text-textColor-dark">
          uBento
        </p>
      </NavLink>

      <nav className="flex items-center justify-center gap-8 ">
        {user && (
          <>
            <motion.div
              {...buttonClick}
              className="relative cursor-pointer"
              onClick={onOpenCartModal}
            >
              <motion.div whileHover={{ scale: 1.1 }}>
                <SiBento className="text-4xl gap-4" />
                <div className="w-6 h-6 rounded-full bg-red-500 flex items-center justify-center absolute -top-4 -right-4 ">
                  <p className="text-primary dark:text-primary-dark text-base font-semibold">
                    {totalQuantity}
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </>
        )}
        <Modal open={openCart} onClose={onCloseCartModal} center>
          <Cart
            cartItems={cartItems}
            removeItemFromCart={removeItemFromCart}
            handleAddToCart={handleAddToCart}
            onCloseCartModal={onCloseCartModal}
          />
        </Modal>

        <>
          <div
            className="relative cursor-pointer"
            onMouseEnter={() => {
              setIsMenu(true);
            }}
            onMouseLeave={() => {
              setIsMenu(false);
            }}
          >
            <div className="w-12 h-12 rounded-full shadow-md cursor-pointer overflow-hidden border-2 border-black flex items-center justify-center">
              <motion.img
                className="w-full h-full object-cover"
                src={user?.picture ? user?.picture : default_avatar}
                whileHover={{ scale: 1.05 }}
                referrerPolicy="no-referrer"
              ></motion.img>
            </div>

            {isMenu && (
              <motion.div
                {...menuAnimation}
                className="px-6 py-4 w-48 backdrop-blur-lg shadow-lg absolute top-12 right-0 flex flex-col gap-4 rounded-lg bg-white "
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive ? isActiveStyles : isNotActiveStyles
                  }
                  to={"/"}
                  onClick={closeMenu}
                >
                  {t("header.home")}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? isActiveStyles : isNotActiveStyles
                  }
                  to={"/restaurant"}
                  onClick={closeMenu}
                >
                  {t("header.restaurant")}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? isActiveStyles : isNotActiveStyles
                  }
                  to={"/menu"}
                  onClick={closeMenu}
                >
                  {t("header.menu")}
                </NavLink>
                {user && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? isActiveStyles : isNotActiveStyles
                    }
                    to={"/user/orders"}
                    onClick={closeMenu}
                  >
                    {t("header.orders")}
                  </NavLink>
                )}

                {isAdmin && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? isActiveStyles : isNotActiveStyles
                    }
                    to={"/dashboard/home"}
                    onClick={closeMenu}
                  >
                    {t("header.dashboard")}
                  </NavLink>
                )}
                <hr />

                <div className="flex flex-col items-center justify-center gap-2">
                  <LanguageDropdownList changeLanguage={changeLanguage} />
                </div>

                <div className="flex flex-col items-center justify-center gap-2">
                  <DarkLightModeToggle
                    isDarkMode={isDarkMode}
                    toggleDarkMode={toggleDarkMode}
                  />
                </div>

                <hr />
                {user && (
                  <motion.div
                    {...buttonClick}
                    onClick={signOut}
                    className="group flex items-center justify-center px-3 py-2 rounded-md shadow-md bg-gray-100 hover:bg-gray-200 gap-3"
                  >
                    <MdLogout className="text-2xl "></MdLogout>
                    <p className="text-xl">{t("header.signOut")}</p>
                  </motion.div>
                )}
              </motion.div>
            )}
          </div>
        </>
        {!user && (
          <>
            <NavLink to={"/login"}>
              <motion.button
                {...buttonClick}
                className="px-4 py-2 rounded-md shadow-md bg-red-500 hover:bg-red-600  text-white"
              >
                {t("header.signIn")}
              </motion.button>
            </NavLink>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
