import React, { useState, useEffect } from "react";
import DBSupplementCard from "./DBSupplementCard";
import {
  deleteSupplement,
  getAllSupplementsWithAllergens,
  updateSupplement,
} from "../../../api/supplements/supplements";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";
import { getAllAllergens } from "../../../api/allergens/allergens";
const DBSupplements = () => {
  const [supplements, setSupplements] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const supplementsFromDB = await getAllSupplementsWithAllergens();
        setSupplements(supplementsFromDB);
      } catch (error) {
        console.error("Error fetching supplements: ", error);
      }
    };
    getAllAllergens().then((allergens) => {
      setAllergenOptions(
        allergens.map((allergen) => ({
          value: allergen.allergen_id,
          label: allergen.allergen_name,
        }))
      );
    });

    fetchSupplements();
  }, []);

  const handleDeleteSupplement = (supplementId) => {
    deleteSupplement(supplementId)
      .then(() => {
        // Filter out the deleted supplement
        const updatedSupplements = supplements.filter(
          (supplement) => supplement.supplement_id !== supplementId
        );
        setSupplements(updatedSupplements);
        notifyToast(
          "success",
          t("DBSupplements.supplementDeleted"),
          "idDBSupplementsSupplementDeleted",
          true
        );
      })
      .catch((error) => {
        console.error("Error deleting food: ", error);
      });
  };

  const handleUpdateSupplement = (updatedSupplement) => {
    console.log("Updating supplement:", updatedSupplement);
    updateSupplement(updatedSupplement.supplement_id, updatedSupplement)
      .then(() => {
        const updatedSupplements = supplements.map((supplement) =>
          supplement.supplement_id === updatedSupplement.supplement_id
            ? updatedSupplement
            : supplement
        );
        setSupplements(updatedSupplements);
        notifyToast(
          "success",
          t("DBSupplements.supplementUpdated"),
          "idDBSupplementsSupplementUpdated",
          true
        );
      })
      .catch((error) => {
        console.error("Error updating supplement: ", error);
      });
  };

  // Filter supplements by name
  const filteredSupplements = supplements.filter((supplement) =>
    supplement.supplement_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBSupplements.supplementsTitle")}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3"
          placeholder={t("DBSupplements.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredSupplements.map((supplement) => (
          <DBSupplementCard
            key={supplement.supplement_id}
            supplement={supplement}
            onDelete={handleDeleteSupplement}
            onUpdate={handleUpdateSupplement}
            allergenOptions={allergenOptions}
          />
        ))}
      </div>
    </div>
  );
};

export default DBSupplements;
