import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import Select from "react-select";

const DBFoodCard = ({
  food,
  onDelete,
  onUpdate,
  allergenOptions,
  categoryOptions,
  supplementOptions,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [editedFood, setEditedFood] = useState({
    ...food,
    food_allergens: food.food_allergens.map((allergen) => ({
      value: allergen.id,
      label: allergen.name,
    })),
    food_category: food.food_category.map((category) => ({
      value: category.id,
      label: category.name,
    })),
    food_supplements: food.food_supplements.map((supplement) => ({
      value: supplement.id,
      label: supplement.name,
    })),
  });

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmModalOpen(false);
    onDelete(food.food_id);
  };

  const handleDeleteModalClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleChange = (inputValue, field) => {
   // Check if the inputValue is an event (for text inputs)
    if (inputValue && inputValue.target) {
      setEditedFood({ ...editedFood, [field]: inputValue.target.value });
    } else {
      // Else, it's a select input
      setEditedFood({ ...editedFood, [field]: inputValue });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const transformSelectDataToIdArray = (selectedItems) => {
      return selectedItems.map((item) => item.value);
    };

    const updatedFood = {
      ...editedFood,
      food_allergens: transformSelectDataToIdArray(editedFood.food_allergens),
      food_category: transformSelectDataToIdArray(editedFood.food_category),
      food_supplements: transformSelectDataToIdArray(
        editedFood.food_supplements
      ),
    };
    onUpdate(updatedFood);
    setModalOpen(false);
  };

  const { t } = useTranslation();
  return (
    <div className="border bg-white dark:bg-slate-100 p-4 rounded shadow flex flex-col justify-between space-y-4">
      <div className="space-y-2">
        <img
          src={food.food_imageURL}
          alt={food.food_name}
          className="w-32 h-32 object-cover mx-auto rounded-full"
        />
        <h3 className="text-lg font-bold text-center">{food.food_name}</h3>

        {/* Category section */}
        <div className="text-center">
          <span className="font-semibold">{t("DBFoodCard.categories")}</span>
          <div className="flex flex-wrap gap-1 justify-center mt-1">
            {food.food_category.length > 0 ? (
              food.food_category.map((category) => (
                <span
                  key={category.id}
                  className="bg-green-200 text-green-700 px-2 py-1 rounded"
                >
                  {category.name}
                </span>
              ))
            ) : (
              <span className="text-gray-500 italic">
                {t("DBFoodCard.noCategories")}
              </span>
            )}
          </div>
        </div>

        {/* Allergens section */}
        <div className="text-center">
          <span className="font-semibold">{t("DBFoodCard.allergens")}</span>
          <div className="flex flex-wrap gap-1 justify-center mt-1">
            {food.food_allergens.length > 0 ? (
              food.food_allergens.map((allergen) => (
                <span
                  key={allergen.id}
                  className="bg-red-200 text-red-700 px-2 py-1 rounded"
                >
                  {allergen.name}
                </span>
              ))
            ) : (
              <span className="text-gray-500 italic">
                {t("DBFoodCard.noAllergens")}
              </span>
            )}
          </div>
        </div>

        {/* Supplements section */}
        <div className="text-center">
          <span className="font-semibold">{t("DBFoodCard.supplements")}</span>
          <div className="flex flex-wrap gap-1 justify-center mt-1">
            {food.food_supplements.length > 0 ? (
              food.food_supplements.map((supplement) => (
                <span
                  key={supplement.id}
                  className="bg-blue-200 text-blue-700 px-2 py-1 rounded"
                >
                  {supplement.name}
                </span>
              ))
            ) : (
              <span className="text-gray-500 italic">
                {t("DBFoodCard.noSupplements")}
              </span>
            )}
          </div>
        </div>

        <div className="flex mb-1">
          <span className="font-semibold">{t("DBFoodCard.price")}</span>
          <span>{food.food_price}€</span>
        </div>
      </div>
      <div className="flex space-x-2 mt-4">
        <button
          onClick={handleOpenModal}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {t("DBFoodCard.update")}
        </button>
        <button
          onClick={handleDeleteClick}
          className="bg-red-500 text-white p-2 rounded"
        >
          {t("DBFoodCard.delete")}
        </button>
      </div>

      {/* Modal for update */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        classNames={{ modal: "h-screen  overflow-y-auto p-6" }}
      >
        <form onSubmit={handleUpdate}>
          <div>
            <h2 className="mt-4 block font-bold text-xl">
            {t("DBFoodCard.updateFood")} {food.food_name}
            </h2>
          </div>
          <div>
            <label className="mt-8 block text-gray-700 text-md font-bold mb-2">
            {t("DBFoodCard.foodName")}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              value={editedFood.food_name}
              onChange={(e) => handleChange(e.target.value, "food_name")}
            />
          </div>
          <div>
            <label className="mt-4 block text-gray-700 text-md font-bold mb-2">
            {t("DBFoodCard.categories")}
            </label>
            <Select
              isMulti
              value={editedFood.food_category}
              onChange={(selected) => handleChange(selected, "food_category")}
              options={categoryOptions}
            />
          </div>
          <div>
            <label className="mt-4 block text-gray-700 text-md font-bold mb-2">
            {t("DBFoodCard.allergens")}
            </label>
            <Select
              isMulti
              value={editedFood.food_allergens}
              onChange={(selected) => handleChange(selected, "food_allergens")}
              options={allergenOptions}
            />
          </div>

          <div>
            <label className="mt-4 block text-gray-700 text-md font-bold mb-2">
            {t("DBFoodCard.supplements")}
            </label>
            <Select
              isMulti
              value={editedFood.food_supplements}
              onChange={(selected) =>
                handleChange(selected, "food_supplements")
              }
              options={supplementOptions}
            />
          </div>
          <div>
            <label className="mt-4 block text-gray-700 text-md font-bold mb-2">
            {t("DBFoodCard.price")}
            </label>
            <input
              className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              value={editedFood.food_price}
              onChange={(e) => handleChange(e.target.value, "food_price")}
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          {t("DBFoodCard.update")}
          </button>
        </form>
      </Modal>

      {/* Modal for delete */}
      <Modal
        open={deleteConfirmModalOpen}
        onClose={handleDeleteModalClose}
        center
      >
        <h2 className="text-2xl font-bold mb-4">{t("DBFoodCard.deleteFood")}</h2>
        <p>{t("DBFoodCard.confirmDelete")} ?</p>
        <div className="flex justify-around mt-4">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
            onClick={handleDeleteConfirm}
          >
            {t("DBFoodCard.delete")}
          </button>
          <button
            className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition duration-300"
            onClick={handleDeleteModalClose}
          >
            {t("DBFoodCard.cancel")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DBFoodCard;
