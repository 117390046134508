import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DBDayScheduleInput = ({ day, schedule, setSchedule }) => {
  const { t } = useTranslation();
  const [newRange, setNewRange] = useState({ start: "", end: "" });

  const handleRangeChange = (index, key, value) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index][key] = value;
    setSchedule(updatedSchedule);
  };

  const addNewRange = () => {
    if (newRange.start && newRange.end) {
      setSchedule([...schedule, newRange]);
      setNewRange({ start: "", end: "" }); // Reset new range
    }
  };

  const removeRange = (index) => {
    const updatedSchedule = [...schedule];
    updatedSchedule.splice(index, 1);
    setSchedule(updatedSchedule);
  };

  return (
    <div className="w-full px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400">
      <h3 className="dark:text-white">{t(`dbSettings.${day}`)}</h3>
      {schedule.map((range, index) => (
        <div key={index} className="flex space-x-2 my-2">
          <input
            type="time"
            value={range.start}
            onChange={(e) => handleRangeChange(index, "start", e.target.value)}
            className="flex-grow min-w-0 sm:min-w-[25%] px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
          />
          <input
            type="time"
            value={range.end}
            onChange={(e) => handleRangeChange(index, "end", e.target.value)}
            className="flex-grow min-w-0 sm:min-w-[25%] px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
          />
          <button
            onClick={() => removeRange(index)}
            className="flex-shrink-0 bg-red-500 text-white p-1 rounded min-w-[25%]"
          >
            {t("dbSettings.remove")}
          </button>
        </div>
      ))}
      <div className="flex space-x-2 my-2">
        <input
          type="time"
          value={newRange.start}
          onChange={(e) => setNewRange({ ...newRange, start: e.target.value })}
          className="flex-grow min-w-0 sm:min-w-[25%] px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
        />
        <input
          type="time"
          value={newRange.end}
          onChange={(e) => setNewRange({ ...newRange, end: e.target.value })}
          className="flex-grow min-w-0 sm:min-w-[25%] px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
        />
        <button
          onClick={addNewRange}
          className="flex-shrink-0 bg-blue-500 text-white p-1 rounded min-w-[25%]"
        >
          {t("dbSettings.add")}
        </button>
      </div>
    </div>
  );
};

export default DBDayScheduleInput;
