import React, { useState, useEffect } from "react";
import DBAllergenCard from "./DBAllergenCard";
import {
  deleteAllergen,
  getAllAllergens,
  updateAllergen,
} from "../../../api/allergens/allergens";
import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";

const DBAllergens = () => {
  const [allergens, setAllergens] = useState([]);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchAllergens = async () => {
      try {
        const allergensFromDB = await getAllAllergens();
        setAllergens(allergensFromDB);
      } catch (error) {
        console.error("Error fetching allergens: ", error);
      }
    };

    fetchAllergens();
  }, []);

  const handleDeleteAllergen = (allergenId) => {
    deleteAllergen(allergenId)
      .then(() => {
        const updatedAllergens = allergens.filter(
          (allergen) => allergen.allergen_id !== allergenId
        );
        setAllergens(updatedAllergens);
        notifyToast(
          "success",
          t("DBAllergens.allergenDeleted"),
          "idDBAllergensAllergenDeleted",
          true
        );
      })
      .catch((error) => {
        console.error("Error deleting allergen: ", error);
      });
  };

  const handleUpdateAllergen = (updatedAllergen) => {
    updateAllergen(updatedAllergen.allergen_id, updatedAllergen)
      .then(() => {
        const updatedAllergens = allergens.map((allergen) =>
          allergen.allergen_id === updatedAllergen.allergen_id
            ? updatedAllergen
            : allergen
        );
        setAllergens(updatedAllergens);
        notifyToast(
          "success",
          t("DBAllergens.allergenUpdated"),
          "idDBAllergensAllergenUpdated",
          true
        );
      })
      .catch((error) => {
        console.error("Error updating allergen: ", error);
      });
  };

  // Filter allergens by name
  const filteredAllergens = allergens.filter((allergen) =>
    allergen.allergen_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-10">
      <h1 className="text-3xl font-bold mb-6 text-center dark:text-red-600">
        {t("DBAllergens.allergensTitle")}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3"
          placeholder={t("DBAllergens.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredAllergens.map((allergen) => (
          <DBAllergenCard
            key={allergen.allergen_id}
            allergen={allergen}
            onDelete={handleDeleteAllergen}
            onUpdate={handleUpdateAllergen}
          />
        ))}
      </div>
    </div>
  );
};

export default DBAllergens;
