import { useState } from "react";
import { toast } from "react-toastify";

const CustomToastContainer = () => {
  const [lastNotificationTime, setLastNotificationTime] = useState({});

  const notifyToast = (type, message, id, ignoreInterval = false) => {
    const currentTime = Date.now();
    const key = `${type}_${id}`;

    if (ignoreInterval) {
      toast[type](<>{message}</>, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } else {
      if (
        !lastNotificationTime[key] ||
        currentTime - lastNotificationTime[key] > 5000
      ) {
        setLastNotificationTime((prev) => ({ ...prev, [key]: currentTime }));
        toast[type](<>{message}</>, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          toastId: id,
          updateId: id,
        });
      }
    }
  };

  return { notifyToast };
};

export default CustomToastContainer;
