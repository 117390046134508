import React from "react";

import { DBLeftSection, DBRightSection } from "../components/Dashboard";
const Dashboard = () => {
  return (
    <div className="w-screen h-screen flex items-center bg-primary dark:bg-primary-dark">
      <DBLeftSection />
      <DBRightSection />
    </div>
  );
};

export default Dashboard;
