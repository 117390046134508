import { api } from "../index";

// Add new supplement
export const addNewSupplement = async (data) => {
  try {
    const res = await api.post(`/supplements/create`, data);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Get all supplement
export const getAllSupplements = async () => {
  try {
    const res = await api.get(`/supplements/all`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllSupplementsWithAllergens = async () => {
  try {
    const res = await api.get(`/supplements/getAllSupplementsWithAllergens`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// Delete a supplement
export const deleteSupplement = async (id) => {
  try {
    const res = await api.delete(`/supplements/delete/${id}`);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update a supplement
export const updateSupplement = async (id, data) => {
  try {
    const res = await api.put(`/supplements/update/${id}`, data);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
