// Styles for the links in the navbar

export const isActiveStyles =
  "text-2xl text-red-700 font-semibold hover:text-red-700 px-4 py-2 duration-100 transition-all ease-in-out";

export const isNotActiveStyles =
  "text-xl text-grey-300  hover:text-red-700 duration-100 px-4 py-2 transition-all ease-in-out ";

export const isActiveStylesDashboard =
  "text-2xl font-semibold px-4 py-2 border-l-8 border-red-500 bg-red-500 text-white transition-all ease-in-out";

export const isNotActiveStylesDashboard =
  "text-xl text-grey-300 hover:text-red-700 duration-100 px-4 py-2 transition-all ease-in-out dark:text-white";
