import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import { updateCart } from "../context/actions/cartActions";
import { getItemIdentifier } from "../context/reducers/cartReducer";
import { getAllSauces } from "../api/sauces/sauces";
import CustomToastContainer from "../components/Notifications/CustomToastContainer";
import SlotReservationPicker from "./SlotReservationPicker";
import StripePayment from "./Stripe/StripePayment";

const Cart = ({
  cartItems,
  removeItemFromCart,
  handleAddToCart,
  onCloseCartModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [sauces, setSauces] = useState([]);
  const [selectedSauce, setSelectedSauce] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const { notifyToast } = CustomToastContainer();

  useEffect(() => {
    fetchAllSauces();
  }, []);

  const fetchAllSauces = async () => {
    try {
      const allSauces = await getAllSauces();
      setSauces(allSauces);
    } catch (error) {
      console.error(t("cart.sauceRetrievalError"), error);
    }
  };

  const handleIncrement = (item) => {
    const newQuantity = item.quantity + 1;
    dispatch(updateCart(getItemIdentifier(item), newQuantity));
  };

  const handleDecrement = (item) => {
    if (item.quantity > 1) {
      const newQuantity = item.quantity - 1;
      dispatch(updateCart(getItemIdentifier(item), newQuantity));
    } else {
      removeItemFromCart(getItemIdentifier(item));
      notifyToast(
        "info",
        item.food.food_name + t("cart.itemRemoved"),
        "idInfoFoodSuppressionCart",
        true
      );
    }
  };

  const handleSlotChange = (slot) => {
    setSelectedDate(slot);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.quantity * item.food.food_price;
    });
    return totalPrice;
  };

  const isPaymentDisabled = () => {
    // Verify if the requirements for payment are met
    return cartItems.length === 0 || !selectedSauce || !selectedDate;
  };

  if (!cartItems || cartItems.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center bg-white p-10 shadow-lg rounded-lg ">
        <p>{t("cart.yourBento")}</p>
        <hr className="border-gray-400 w-full mb-4" />
        <p>{t("cart.bentoIsEmpty")}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center bg-white p-10 shadow-lg rounded-lg ">
      <h2 className="text-3xl font-semibold mb-4">{t("cart.yourBento")}</h2>
      <hr className="border-gray-400 w-full mb-4" />

      <ul className="w-full">
        {cartItems.map((item) => (
          <li
            key={item.itemIdentifier}
            className="border-b border-gray-400 mb-4 pb-2 flex flex-col "
          >
            <div className="gap-4">
              <div className="flex items-center text-center justify-center  my-2">
                <button
                  onClick={() => handleDecrement(item)}
                  className="bg-red-500 text-white px-4 py-2 mr-2 rounded-full hover:bg-red-600 transition duration-300 ease-in-out font-semibold"
                >
                  <AiOutlineMinus />
                </button>
                <p className="font-semibold text-lg text-center w-6">
                  {item.quantity}
                </p>
                <button
                  onClick={() => handleIncrement(item)}
                  className="bg-green-500 text-white px-4 py-2 ml-2 rounded-full hover:bg-green-600 transition duration-300 ease-in-out"
                >
                  <AiOutlinePlus />
                </button>
              </div>
              <p className="font-semibold text-lg text-center">
                {item.food.food_name}
              </p>
              <p className="text-gray-600 text-center italic ">
                {item.supplements
                  .map((supplement) => supplement.supplement_name)
                  .join(", ")}
              </p>
              <p className="text-center font-semibold">
                {(item.quantity * item.food.food_price).toFixed(2)} €
              </p>
            </div>
          </li>
        ))}

        <select
          value={selectedSauce}
          onChange={(e) => setSelectedSauce(e.target.value)}
          className="w-full px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400 mt-4"
        >
          <option value="">{t("cart.chooseSauceOption")}</option>
          {sauces.map((sauce) => (
            <option key={sauce.sauce_id} value={sauce.sauce_name}>
              {sauce.sauce_name}
            </option>
          ))}
        </select>

        <SlotReservationPicker onSlotChange={handleSlotChange} />

        {cartItems.length > 0 && (
          <div className="mt-4 justify-center text-center">
            <p className="font-semibold text-lg">
              {t("cart.total")} {calculateTotalPrice().toFixed(2) + " €"}
            </p>
          </div>
        )}

        <div className="text-center">
          <StripePayment
            cartItems={cartItems}
            user={user}
            onCloseCartModal={onCloseCartModal}
            disabled={isPaymentDisabled()}
            selectedSauce={selectedSauce}
            selectedDate={selectedDate}
          />
        </div>
      </ul>
    </div>
  );
};

export default Cart;
