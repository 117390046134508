import React, { useState } from "react";
import { Spinner } from "..";
import { FaCloudUploadAlt, MdDelete } from "../../../assets/icons";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../config/firebase.config";
import { useDispatch } from "react-redux";

import { motion } from "framer-motion";
import { buttonClick } from "../../../animations";
import { addNewSupplement } from "../../../api/supplements/supplements";
import { useTranslation } from "react-i18next";

import CustomToastContainer from "../../Notifications/CustomToastContainer";
import DBAllergensList from "../DBAjoutAllergens/DBAllergensList";

const InputValueField = ({ type, placeHolder, stateFunction, stateValue }) => {
  return (
    <input
      type={type}
      placeholder={placeHolder}
      value={stateValue}
      onChange={(e) => stateFunction(e.target.value)}
      className="w-full px-4 py-3 shadow-md outline-none rounded-md border focus:border-red-400"
    />
  );
};

const DBNewSupplement = () => {
  const [supplementName, setSupplementName] = useState("");
  const [allergen, setAllergen] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [imageDownloadURL, setImageDownloadURL] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(true);
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();

  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(
      storage,
      `Images/supplements/${Date.now()}_${imageFile.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        notifyToast(
          "error",
          `${t("dbNewSupplement.error")} + error`,
          "idError444areretet35983968"
        );
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageDownloadURL(downloadURL);
          setIsLoading(false);
          setProgress(null);
          notifyToast(
            "success",
            t("dbNewSupplement.imageUploaded"),
            "idSuccessUploadTask35arzrazr981454653968"
          );
        });
      }
    );
  };

  const deleteImageFromFirebase = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, imageDownloadURL);
    deleteObject(deleteRef).then(() => {
      setImageDownloadURL(null);
      setIsLoading(false);
      notifyToast(
        "success",
        t("dbNewSupplement.imageDeleted"),
        "idSuccessDeletedTask3ATHGREHERH8"
      );
    });
  };

  const submitNewData = () => {
    if (!supplementName.trim()) {
      if (isToastVisible) {
        notifyToast(
          "error",
          t("dbNewSupplement.errorEmptySupplementName"),
          "idEnterSupplementNamegkroeigjreiojh35983968"
        );
        setIsToastVisible(false);
        setTimeout(() => setIsToastVisible(true), 5000);
      }
      return;
    }
    const data = {
      supplement_name: supplementName,
      supplement_allergens: allergen,
      imageURL: imageDownloadURL,
    };
    addNewSupplement(data)
      .then((res) => {
        notifyToast(
          "success",
          `${t("dbNewSupplement.newSupplement")} "${supplementName}" ${t(
            "dbNewSupplement.added"
          )}`,
          "idNewSupplement305283058230"
        );
        setImageDownloadURL(null);
        setSupplementName("");
        setAllergen([]);
      })
      .catch((error) => {
        console.error("Error while adding new supplement:", error);
      });
  };

  return (
    <div className="flex items-center justify-center flex-col pt-6 lg:px-24 w-full">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600">
        {t("dbNewSupplement.addNewSupplement")}
      </h1>
      <div className="border  rounded-lg p-4 w-full flex flex-col items-center justify-center gap-4 bg-white dark:bg-slate-100">
        <InputValueField
          type="text"
          placeHolder={t("dbNewSupplement.supplementName")}
          stateFunction={setSupplementName}
          stateValue={supplementName}
        />

        <DBAllergensList
          setAllergen={setAllergen}
          selectedAllergens={allergen}
        />
        <div className="w-full bg-card dark:bg-card-dark backdrop-blur-md h-370 rounded-md border-2 border-dotted border-gray-300 cursor-pointer">
          {isLoading ? (
            <div className="w-full h-full flex flex-col items-center justify-evenly px-24">
              <Spinner />
              {Math.round(progress > 0) && (
                <div className=" w-full flex flex-col items-center justify-center gap-2">
                  <div className="flex justify-between w-full">
                    <span className="text-base font-medium text-textColor dark:text-textColor-dark">
                      Progress
                    </span>
                    <span className="text-sm font-medium text-textColor dark:text-textColor-dark">
                      {Math.round(progress) > 0 && (
                        <>{`${Math.round(progress)}%`}</>
                      )}
                    </span>
                  </div>

                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-red-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                      style={{
                        width: `${Math.round(progress)}%`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {!imageDownloadURL ? (
                <>
                  <label>
                    <div className="flex flex-col items-center justify-center h-full w-full cursor-pointer bg-slate-100 rounded-lg">
                      <div className="flex flex-col justify-center items-center cursor-pointer">
                        <p className="font-bold text-4xl">
                          <FaCloudUploadAlt cn="-rotate-0" />
                        </p>
                        <p className="text-lg">{t("clickToUploadImage")}</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      name="upload-image"
                      accept="image/*"
                      onChange={uploadImage}
                      className="w-0 h-0"
                    />
                  </label>
                </>
              ) : (
                <>
                  <div className="relative w-full h-full overflow-hidden rounded-md">
                    <motion.img
                      whileHover={{ scale: 1.15 }}
                      src={imageDownloadURL}
                      className=" w-full h-full object-contain"
                    />

                    <div
                      className="absolute top-4 right-4 w-10 h-10 rounded-full bg-red-500 flex items-center justify-center cursor-pointer"
                      onClick={deleteImageFromFirebase}
                    >
                      <MdDelete className="w-5 h-5 text-white" />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="flex items-center justify-center w-full">
          <motion.button
            onClick={submitNewData}
            {...buttonClick}
            className="w-9/12 py-2 rounded-md bg-red-400 text-primary dark:text-primary-dark hover:bg-red-500 cursor-pointer"
          >
            {t("dbNewItem.saveButton")}
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default DBNewSupplement;
