import React from "react";
import { useTranslation } from "react-i18next";

const DBNotifications = ({ discordNotifications, setDiscordNotifications }) => {
  const { t } = useTranslation();
  const handleDiscordNotificationChange = (event) => {
    setDiscordNotifications(event.target.checked);
  };

  return (
    <div>
      <label className="mr-4">{t("dbSettings.discordNotifications")}</label>
      <input
        type="checkbox"
        checked={discordNotifications}
        onChange={handleDiscordNotificationChange}
        className="form-checkbox h-4 w-4 text-gray-600 dark:text-red-600"
      />
    </div>
  );
};

export default DBNotifications;
