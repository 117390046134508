import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const DBOrdersPerDayChart = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  // Extract unique month-years from data
  const uniqueMonthYears = Array.from(
    new Set(Object.keys(data).map((key) => key.slice(0, 7)))
  );

  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Find the closest month-year to the current date
  const findClosestMonthYear = () => {
    const currentMonthYear = moment().format("YYYY-MM");
    if (uniqueMonthYears.includes(currentMonthYear)) {
      return currentMonthYear;
    }
    return uniqueMonthYears[0] || "";
  };

  useEffect(() => {
    if (!isInitialized && uniqueMonthYears.length > 0) {
      const monthYearToUse = findClosestMonthYear();
      setSelectedMonthYear(monthYearToUse);
      filterData(monthYearToUse);
      setIsInitialized(true);
    }
  }, [uniqueMonthYears, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      filterData(selectedMonthYear);
    }
  }, [selectedMonthYear]);

  const filterData = (monthYear) => {
    const filtered = Object.entries(data)
      .filter(([date]) => date.startsWith(monthYear))
      .map(([date, orders]) => ({ day: date, orders: Number(orders) }));

    setFilteredData(filtered);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MMM");
  };

  const formatMonthYear = (monthYear) => {
    return moment(monthYear).locale(i18n.language).format("MMMM YYYY");
  };

  return (
    <div className="flex flex-col items-center w-full my-16">
      <h2 className="text-center mb-4 font-semibold dark:text-red-500">
        {t("DBOrdersPerDayChart.title")}
      </h2>
      <select
        value={selectedMonthYear}
        onChange={(e) => {
          if (e.target.value !== selectedMonthYear) {
            setSelectedMonthYear(e.target.value);
          }
        }}
        className="mb-4 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      >
        {uniqueMonthYears.map((monthYear) => (
          <option key={monthYear} value={monthYear}>
            {formatMonthYear(monthYear)}
          </option>
        ))}
      </select>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={filteredData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            name={t("DBOrdersPerDayChart.day")}
            tickFormatter={formatDate}
          />
          <YAxis
            label={{
              value: t("DBOrdersPerDayChart.orders"),
              angle: -90,
              position: "insideLeft",
            }}
            allowDecimals={false}
          />
          <Tooltip
            formatter={(value) => [
              `${value} `,
              t("DBOrdersPerDayChart.orders"),
            ]}
            labelFormatter={formatDate}
          />
          <Legend />
          <Bar
            dataKey="orders"
            name={t("DBOrdersPerDayChart.orders")}
            fill="#8884d8"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DBOrdersPerDayChart;
