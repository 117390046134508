import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { useTranslation } from "react-i18next";
const DBSauceCard = ({ sauce, onDelete, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [editedSauce, setEditedSauce] = useState({ ...sauce });
  const { t } = useTranslation();
  const handleOpenModal = () => setModalOpen(true);
  const handleDeleteClick = () => setDeleteConfirmModalOpen(true);
  const handleDeleteConfirm = () => {
    onDelete(sauce.sauce_id);
    setDeleteConfirmModalOpen(false);
  };
  const handleDeleteModalClose = () => setDeleteConfirmModalOpen(false);
  const handleChange = (inputValue, field) => {
    setEditedSauce({ ...editedSauce, [field]: inputValue });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editedSauce);
    setModalOpen(false);
  };

  return (
    <div className="border rounded overflow-hidden shadow-lg bg-white dark:bg-slate-100">
      <div>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{sauce.sauce_name}</div>
        </div>
      </div>
      <div className="flex space-x-2 mt-4 p-4">
        <button
          onClick={handleOpenModal}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {t("DBSauceCard.update")}
        </button>
        <button
          onClick={handleDeleteClick}
          className="bg-red-500 text-white p-2 rounded"
        >
          {t("DBSauceCard.delete")}
        </button>
      </div>

      {/* Modal for update */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        classNames={{ modal: "overflow-y-auto p-6" }}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <h2 className="mt-4 block font-bold text-xl">
              {t("DBSauceCard.updateSauce")} <br /> {sauce.sauce_name}
            </h2>
          </div>
          <div className="my-4">
            <label className="mt-8 block text-gray-700 text-md font-bold mb-2">
              {t("DBSauceCard.sauceName")}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              value={editedSauce.sauce_name}
              onChange={(e) => handleChange(e.target.value, "sauce_name")}
            />
          </div>

          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            {t("DBSauceCard.update")}
          </button>
        </form>
      </Modal>

      {/* Modal for delete */}
      <Modal
        open={deleteConfirmModalOpen}
        onClose={handleDeleteModalClose}
        center
      >
        <h2 className="text-2xl font-bold mb-4">
          {t("DBSauceCard.deleteTheSauce")}
        </h2>
        <p>{t("DBSauceCard.deleteConfirm")}</p>
        <div className="flex justify-around mt-4">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
            onClick={handleDeleteConfirm}
          >
            {t("DBSauceCard.delete")}
          </button>
          <button
            className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition duration-300"
            onClick={handleDeleteModalClose}
          >
            {t("DBSauceCard.cancel")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DBSauceCard;
