import { api } from "../index";

// Add new sauce
export const addNewSauce = async (data) => {
  try {
    const res = await api.post(`/sauces/create`, data);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Get all sauces
export const getAllSauces = async () => {
  try {
    const res = await api.get(`/sauces/all`);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update sauce
export const updateSauce = async (id, data) => {
  try {
    const res = await api.put(`/sauces/update/${id}`, data);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Delete sauce
export const deleteSauce = async (id) => {
  try {
    const res = await api.delete(`/sauces/delete/${id}`);
    return res.data.data;
  } catch (err) {
    return null;
  }
};
