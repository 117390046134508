import React, { useEffect, useState } from "react";
import { MdLogout } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { app } from "../../config/firebase.config";
import { setUserNULL } from "../../context/actions/userActions";
import { useTranslation } from "react-i18next";
import DBHamburgerMenu from "./DBHamburgerMenu/DBHamburgerMenu";
import DarkLightModeToggle from "../Header/DarkLightModeToggle";
const DBHeader = () => {
  const user = useSelector((state) => state.user);
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true";
  });
  const signOut = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        dispatch(setUserNULL());
        navigate("/login", { replace: true });
      })
      .catch((err) => console.log(err));
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, []);
  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem("darkMode", newMode);
    document.documentElement.classList.toggle("dark", newMode);
  };

  return (
    <div className="w-full flex items-center justify-between gap-3 pb-20 lg:pb-0">
      <div className="lg:hidden">
        <DBHamburgerMenu
          isMenuOpen={isMenuOpen}
          handleMenuClick={handleMenuClick}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
      <p className="text-2xl dark:text-red-600 hidden lg:block">
        {t("dbHeader.adminDashboard")}{" "}
        {user?.name && (
          <span className="text-base text-gray-500 dark:text-white hidden lg:block">{`${t(
            "dbHeader.bonjour"
          )} ${user?.name} !`}</span>
        )}
      </p>

      <div className="flex items-center justify-center gap-2">
        <div className="flex items-center justify-center gap-2 ">
          <div className="fixed lg:top-12 top-4 right-24 bg-white backdrop-blur-md shadow-md rounded-md z-[10]">
            <DarkLightModeToggle
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
              showText={false}
            />
          </div>
          <motion.div
            onClick={signOut}
            className="fixed lg:top-12 top-4 right-8 w-10 h-10 rounded-md cursor-pointer bg-white backdrop-blur-md shadow-md flex items-center justify-center z-[10]"
          >
            <MdLogout className="text-xl" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DBHeader;
