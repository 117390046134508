import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h2 className="text-3xl font-semibold mb-4">{t("accessDenied.title")}</h2>
      <p className="text-xl">{t("accessDenied.message")}</p>
      <Link to={"/"}>
        <button className="border border-red-500 rounded-md px-4 py-2 bg-red-500 text-white mt-4">
          {t("accessDenied.button")}
        </button>
      </Link>
    </div>
  );
};

export default AccessDenied;
