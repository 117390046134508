export const addToCart = (cart) => (dispatch, getState) => {
  dispatch({
    type: "ADD_TO_CART",
    payload: cart,
  });

  // Save cart to localStorage after adding to cart
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.items));
};
export const removeFromCart = (itemIdentifier) => (dispatch) => {
  dispatch({
    type: "REMOVE_FROM_CART",
    payload: itemIdentifier,
  });
};

export const updateCart =
  (itemIdentifier, newQuantity) => (dispatch, getState) => {
    dispatch({
      type: "UPDATE_CART",
      payload: {
        itemIdentifier,
        newQuantity,
      },
    });

    localStorage.setItem("cartItems", JSON.stringify(getState().cart.items));
  };

export const resetCart = () => (dispatch) => {
  dispatch({
    type: "RESET_CART",
  });

  // Reinstate cart to localStorage after resetting cart
  localStorage.removeItem("cartItems");
};
