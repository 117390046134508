import React, { useEffect } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { buttonClick } from "../../animations";
const MenuParCategorie = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <motion.div className="w-full  select-none">
      <div className="flex flex-col items-center justify-start gap-12">
        <p
          className="text-[45px] xl:text-[72px] md:text-[70px] font-sans font-extrabold tracking-wider"
          data-aos="fade-zoom-in"
          data-aos-delay="100"
        >
          Notre{" "}
          <span
            className="text-red-600"
            data-aos="fade-zoom-in"
            data-aos-delay="150"
          >
            Menu
          </span>
        </p>

        <div className="flex flex-col items-center ">
          <span data-aos="fade-zoom-in" data-aos-delay="500">
            <motion.button
              whileHover={{ scale: 1.25 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-600 px-12 py-6 rounded-full text-white text-base font-semibold shadow-lg  hover:shadow-xl  hover:bg-gradient-to-r hover:from-pink-600 hover:via-red-600
               hover:to-yellow-600 mb-5 "
            >
              À la carte
            </motion.button>
          </span>

          <span data-aos="fade-zoom-in" data-aos-delay="600">
            <motion.button
              {...buttonClick}
              whileHover={{ scale: 1.25 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-600 px-12 py-6 rounded-full text-white text-base font-semibold shadow-lg  hover:shadow-xl  hover:bg-gradient-to-r hover:from-pink-600 hover:via-red-600 hover:to-yellow-600
              mt-5"
            >
              Par catégorie
            </motion.button>
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default MenuParCategorie;
