import React from "react";
import { useTranslation } from "react-i18next";
import { FaClock, FaTimes } from "react-icons/fa";

const RestaurantSchedule = ({ scheduleDays }) => {
  const { t } = useTranslation();
  const orderedDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return (
    <div className="schedule-container space-y-4">
      {orderedDays.map((day) => (
        <div key={day} className="flex items-center space-x-4">
          <strong className="flex-none w-24 text-left dark:text-red-500">
            {t(`RestaurantSchedule.days.${day}`)}
          </strong>
          <div className="flex-grow">
            {scheduleDays[day].length > 0 ? (
              scheduleDays[day].map((slot, index) => (
                <div key={index} className="flex items-center mb-2">
                  <FaClock className="text-green-500 mr-2" />
                  <span className="text-sm dark:text-white">
                    {slot.start} - {slot.end}
                  </span>
                </div>
              ))
            ) : (
              <div className="flex items-center">
                <FaTimes className="text-red-500 mr-2" />
                <span className="text-sm dark:text-white">
                  {t("RestaurantSchedule.closed")}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RestaurantSchedule;
