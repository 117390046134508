import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserOrders } from "../../api/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import { MdDateRange, MdAddCircle, MdExpandMore, MdDone } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import QRCode from "react-qr-code";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { buttonClick } from "../../animations";
import { motion, AnimatePresence } from "framer-motion";
import { BiErrorCircle } from "react-icons/bi";
import { FaQrcode } from "react-icons/fa";
import CustomToastContainer from "../Notifications/CustomToastContainer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resetCart } from "../../context/actions/cartActions";

const UserOrders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const user = useSelector((state) => state.user);
  const [activeOrderId, setActiveOrderId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { notifyToast } = CustomToastContainer();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const response = await getUserOrders(user.user_id);
        if (response.success && Array.isArray(response.data)) {
          setUserOrders(response.data);
        } else {
          console.error("Fetched data is not an array:", response);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchUserOrders();
  }, [user.user_id]);

  useEffect(() => {
    // Check if the user has been redirected from the payment page
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get("paymentStatus");
    if (paymentStatus === "success") {
      dispatch(resetCart());
      notifyToast(
        "success",
        t("userOrders.orderPaidSuccess"),
        "idUserOrderPaidSuccessful"
      );
    }
  }, [location]);

  const handleOrderClick = (orderId) => {
    setActiveOrderId(activeOrderId === orderId ? null : orderId);
  };

  const handleOpenModal = (event, order) => {
    event.stopPropagation();
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getStatus = (status) => {
    switch (status) {
      case "En cours":
        return {
          icon: <FaSpinner className="inline-block mr-2 text-xl" />,
          color: "bg-yellow-400",
        };
      case "En attente":
        return {
          icon: <FaSpinner className="inline-block mr-2 text-xl" />,
          color: "bg-yellow-700",
        };
      case "Terminée":
        return {
          icon: <MdDone className="inline-block mr-2 text-xl" />,
          color: "bg-green-500",
        };
      case "Annulée":
        return {
          icon: <BiErrorCircle className="inline-block mr-2 text-xl" />,
          color: "bg-red-300",
        };
      case "Payée":
        return {
          icon: <MdDone className="inline-block mr-2 text-xl" />,
          color: "bg-green-500",
        };
      default:
        return { icon: null, color: "bg-gray-500" };
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center 2xl:mt-48 md:mt-48 mt-32  p-4 mb-32">
      {userOrders.length === 0 ? (
        <p className="text-xl dark:text-white">{t("userOrders.noOrdersYet")}</p>
      ) : (
        <>
          <h2 className="text-4xl font-semibold mb-4 dark:text-red-600">
            {t("userOrders.yourOrders")} 👨‍🍳
          </h2>
          <div className="w-full xl:w-5/6   mx-auto p-5 lg:px-24">
            {userOrders &&
              userOrders.length > 0 &&
              userOrders.map((order) => (
                <div
                  key={order.orderNumber}
                  className={`bg-white-500 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-5  ${
                    activeOrderId === order.orderNumber
                      ? "border border-red-400"
                      : ""
                  }`}
                  onClick={() => handleOrderClick(order.orderNumber)}
                >
                  <p className="w-full font-semibold bg-red-500 flex items-center justify-center p-2 rounded-t-lg text-white ">
                    <span className="flex-grow">
                      <MdDateRange className="inline-block mr-2 text-lg" />
                      {new Date(order.date).toLocaleString("fr-FR", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </span>
                    <span className="flex items-center">
                      <button
                        onClick={(event) => handleOpenModal(event, order)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        <FaQrcode className="text-xl" />
                      </button>
                      <MdExpandMore className="inline-block ml-2 text-4xl text-white" />
                    </span>
                  </p>

                  <AnimatePresence initial={false}>
                    {activeOrderId === order.orderNumber && (
                      <motion.div
                        key={order.orderNumber}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="overflow-hidden"
                      >
                        {order.items &&
                          order.items.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-col items-center dark:text-white"
                            >
                              <div className="grid grid-cols-4  items-center font-semibold text-lg w-full p-2">
                                <span className="text-right mx-5">
                                  {item.food_quantity} x{" "}
                                </span>

                                <span className="text-left  col-span-2">
                                  {item.food_name}
                                </span>

                                <span className="text-right">
                                  {item.food_price} €
                                </span>
                              </div>

                              {item.food_supplements &&
                                item.food_supplements.map(
                                  (supplement, sIndex) => (
                                    <div
                                      key={sIndex}
                                      className={`grid grid-cols-4 items-center justify-center font-semibold text-lg w-full ${
                                        sIndex ===
                                        item.food_supplements.length - 1
                                          ? "pb-2"
                                          : ""
                                      } `}
                                    >
                                      <span className="text-right mx-5"></span>
                                      <span className="text-left col-span-3 align-middle justify-center">
                                        {" "}
                                        <MdAddCircle className="inline-block mr-1 text-green-500 text-lg" />{" "}
                                        {supplement.supplement_name}
                                      </span>
                                    </div>
                                  )
                                )}

                              {index < order.items.length - 1 && (
                                <hr className=" w-full  border-gray-400 dark:border-red-400" />
                              )}
                            </div>
                          ))}

                        <p className="font-semibold text-center pb-2 bg-orange-200 text-black py-1 px-4 ">
                          Sauce : {order.sauce}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <div
                    className={`p-3 flex justify-between ${
                      getStatus(order.statut).color
                    } rounded-bl-lg rounded-br-lg`}
                  >
                    {/* Order state to the left*/}
                    <span className="flex items-center">
                      {getStatus(order.statut).icon} Commande {order.statut}
                    </span>

                    {/* Total to the right */}
                    <span className="flex items-center">
                      TOTAL {order.orderTotal} €
                    </span>
                  </div>
                </div>
              ))}
            {/* Modal for displaying QR Code */}
            <Modal open={openModal} onClose={handleCloseModal} center>
              <h2
                style={{
                  color: "#E63946",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Date de la Commande
              </h2>
              {selectedOrder && (
                <div style={{ textAlign: "center" }}>
                  <h3 style={{ color: "#1D3557", margin: "10px 0" }}>
                    {new Date(selectedOrder.date).toLocaleString("fr-FR", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </h3>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "white",
                      display: "inline-block",
                      border: "1px solid #A8DADC",
                    }}
                  >
                    <QRCode value={selectedOrder.orderNumber.toString()} />
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
      <Link to={"/"}>
        <motion.button
          {...buttonClick}
          className="px-6 py-3 rounded-md bg-red-500 text-primary dark:text-primary-dark hover:bg-red-600 cursor-pointer mt-8 "
        >
          Retour
        </motion.button>
      </Link>
    </div>
  );
};

export default UserOrders;
