import { combineReducers } from "redux";
import userReducers from "./userReducers";
import alertReducer from "./alertReducers";
import foodReducer from "./foodReducer";
import supplementReducer from "./supplementReducer";
import categoryReducer from "./categoryReducer";
import cartReducer from "./cartReducer";
const myReducers = combineReducers({
  user: userReducers,
  alert: alertReducer,
  foods: foodReducer,
  supplements: supplementReducer,
  categories: categoryReducer,
  cart: cartReducer,
});

export default myReducers;
