import { api } from "../index";

// Get available slots
export const getAvailableSlots = async () => {
  try {
    const response = await api.get(`/reservationSlots/availableSlots`);
    if (response.data.success) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching available slots:", error);
    return [];
  }
};
