import React from "react";
import { FaCloudUploadAlt, MdDelete } from "../../assets/icons";
import { motion } from "framer-motion";
import { buttonClick } from "../../animations";
import { Spinner } from "./";
import { t } from "i18next";

const ImageUpload = ({
  isLoading,
  progress,
  imageDownloadURL,
  uploadImage,
  deleteImageFromFirebase,
}) => {
  return (
    <div className="w-full bg-card dark:bg-card-dark backdrop-blur-md h-370 rounded-md border-2 border-dotted border-gray-300 cursor-pointer">
      {isLoading ? (
        <div className="w-full h-full flex flex-col items-center justify-evenly px-24">
          <Spinner />
          {Math.round(progress > 0) && (
            <div className=" w-full flex flex-col items-center justify-center gap-2">
              <div className="flex justify-between w-full">
                <span className="text-base font-medium text-textColor dark:text-textColor-dark">
                  Progress
                </span>
                <span className="text-sm font-medium text-textColor dark:text-textColor-dark">
                  {Math.round(progress) > 0 && (
                    <>{`${Math.round(progress)}%`}</>
                  )}
                </span>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-red-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                  style={{
                    width: `${Math.round(progress)}%`,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {!imageDownloadURL ? (
            <>
              <label>
                <div className="flex flex-col items-center justify-center h-full w-full cursor-pointer bg-slate-100 rounded-lg">
                  <div className="flex flex-col justify-center items-center cursor-pointer">
                    <p className="font-bold text-4xl">
                      <FaCloudUploadAlt cn="-rotate-0" />
                    </p>
                    <p className="text-lg">{t("clickToUploadImage")}</p>
                  </div>
                </div>
                <input
                  type="file"
                  name="upload-image"
                  accept="image/*"
                  onChange={uploadImage}
                  className="w-0 h-0"
                />
              </label>
            </>
          ) : (
            <>
              <div className="relative w-full h-full overflow-hidden rounded-md">
                <motion.img
                  whileHover={{ scale: 1.15 }}
                  src={imageDownloadURL}
                  className=" w-full h-full object-contain"
                />

                <motion.button
                  {...buttonClick}
                  type="button"
                  className="absolute top-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"
                  onClick={() => deleteImageFromFirebase(imageDownloadURL)}
                >
                  <MdDelete className="-rotate-0" />
                </motion.button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ImageUpload;
