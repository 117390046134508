// This function handle the border/background color of the status of the order
export const handleBorderStatus = (status) => {
  switch (status) {
    case "En attente":
      return "border-yellow-500 border bg-yellow-400";
    case "En cours":
      return "border-blue-500 border bg-blue-400";
    case "Payée":
      return "border-green-500 border bg-green-400";
    case "Terminée":
      return "border-gray-500 border bg-gray-400";
    default:
      return "border-yellow-500 border bg-yellow-400";
  }
};
