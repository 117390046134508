import React, { useEffect, useState } from "react";

import { getAllCategories } from "../../../api/categories/categories";
import { useTranslation } from "react-i18next";
const DBCategoriesList = ({ setCategory, selectedCategories }) => {
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesFromDB = await getAllCategories();
        setCategories(categoriesFromDB);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des catégories : ",
          error
        );
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    setCategory((prevCategory) => {
      if (prevCategory.includes(categoryId)) {
        return prevCategory.filter((id) => id !== categoryId);
      } else {
        return [...prevCategory, categoryId];
      }
    });
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">
        {t("DBCategoriesList.selectCategories")}
      </h2>

      <div className="flex items-center justify-around gap-3 flex-wrap">
        {categories &&
          categories.map((data) => (
            <p
              key={data.category_id}
              onClick={() => handleCategoryClick(data.category_id)}
              className={`px-4 py-3 rounded-md text-xl font-semibold cursor-pointer hover:shadow-md border border-gray-200 backdrop-blur-md ${
                selectedCategories.includes(data.category_id)
                  ? "bg-red-400 text-primary dark:text-primary-dark"
                  : "bg-transparent"
              }`}
            >
              {data.category_name}
            </p>
          ))}
      </div>
    </div>
  );
};

export default DBCategoriesList;
