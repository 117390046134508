import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { buttonClick } from "../../../animations";
import { useTranslation } from "react-i18next";
const DBSupplementsList = ({
  supplements,
  selectedSupplements,
  handleSelectAllChange,
  handleSupplementChange,
}) => {
  const [checkboxState, setCheckboxState] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    const initialCheckboxState = {};
    supplements.forEach((supplement) => {
      initialCheckboxState[supplement.supplement_id] =
        selectedSupplements.includes(supplement.supplement_id);
    });
    setCheckboxState(initialCheckboxState);
  }, [supplements, selectedSupplements]);

  const handleChange = (event, supplementId) => {
    const isChecked = event.target.checked;
    handleSupplementChange(supplementId, isChecked);
    setCheckboxState((prevState) => ({
      ...prevState,
      [supplementId]: isChecked,
    }));
  };

  return (
    <div className="flex flex-col p-5">
      <h2 className="text-2xl font-semibold mb-4">
        {t("dbSupplementList.supplementAllowed")}
      </h2>
      {supplements.length > 1 && (
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="select-all-supplements"
            onChange={handleSelectAllChange}
            checked={selectedSupplements.length === supplements.length}
            className="form-checkbox h-5 w-5 text-red-600 self-center mr-2"
          />
          <label htmlFor="select-all-supplements">
            {t("dbSupplementList.selectAll")}
          </label>
        </div>
      )}

      <div className="grid grid-cols-3 gap-4">
        {supplements.map((supplement) => (
          <div
            key={supplement.supplement_id}
            className={`flex flex-col border-4 rounded-md p-2  ${
              checkboxState[supplement.supplement_id]
                ? " border-green-500"
                : "border-transparent"
            }`}
          >
            <motion.div
              {...buttonClick}
              className="flex flex-col items-center cursor-pointer"
              draggable="false"
              onClick={(e) => {
                e.target.checked = !checkboxState[supplement.supplement_id];
                handleChange(e, supplement.supplement_id);
              }}
            >
              <div className="w-24 h-24 overflow-hidden rounded-full mb-2">
                <img
                  src={supplement.imageURL}
                  alt={supplement.supplement_name}
                  className="w-full h-full object-contain"
                  draggable="false"
                  style={{ userSelect: "none" }}
                />
              </div>
              <span
                className="text-lg font-medium"
                style={{ userSelect: "none" }}
              >
                {supplement.supplement_name}
              </span>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DBSupplementsList;
