// DarkLightModeToggle.jsx
import React from "react";
import { useTranslation } from "react-i18next";
import MoonSVGIcon from "../../assets/svg/MoonSVGIcon";
import SunSVGIcon from "../../assets/svg/SunSVGIcon";
import { toggleDarkLightModeAnimation } from "../../animations/index";
const DarkLightModeToggle = ({
  isDarkMode,
  toggleDarkMode,
  showText = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-full flex items-center justify-between">
      <button
        onClick={toggleDarkMode}
        className="flex py-2 px-2 items-center w-full rounded-md border shadow-md"
      >
        {isDarkMode ? (
          <>
            <MoonSVGIcon
              fill="#000"
              stroke="#fff"
              size={24}
              variants={toggleDarkLightModeAnimation}
              initial="sun"
              animate="moon"
            />
            {showText && <span className="ml-4 text-lg">{t("darkMode")}</span>}
          </>
        ) : (
          <>
            <SunSVGIcon
              stroke="#000"
              size={24}
              variants={toggleDarkLightModeAnimation}
              initial="moon"
              animate="sun"
            />
            {showText && (
              <span className="ml-4 text-lg">{t("lightMode")} </span>
            )}
          </>
        )}
      </button>
    </div>
  );
};

export default DarkLightModeToggle;
