import React, { useState } from "react";
import InputValueField from "../InputValueField";
import BTNSave from "../../Buttons/BTNSave";

import { addNewAllergen } from "../../../api/allergens/allergens";

import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";

const DBAjoutAllergen = () => {
  const [allergen, setAllergen] = useState("");
  const { notifyToast } = CustomToastContainer();
  const [isAddingAllergenLoading, setIsAddingAllergenLoading] = useState(false);
  const { t } = useTranslation();

  const submitNewData = () => {
    if (!allergen.trim()) {
      notifyToast(
        "error",
        t("dbAjoutAllergen.errorEmptyAllergenName"),
        "idErrorEntrezUnNomPourAddAllergen"
      );

      return;
    }

    if (isAddingAllergenLoading) {
      return;
    }

    setIsAddingAllergenLoading(true);
    const data = {
      allergen_name: allergen,
    };
    addNewAllergen(data)
      .then((res) => {
        notifyToast(
          "success",
          `${t("dbAjoutAllergen.newAllergen")} "${allergen}" ${t(
            "dbAjoutAllergen.added"
          )}`,
          "idSuccessNewAllergenAdded"
        );
        setAllergen("");
      })
      .catch((error) => {
        notifyToast(
          "error",
          t("dbAjoutAllergen.errorWhenAddingNewAllergen"),
          "idErrorAddingAllergen"
        );
        console.error(t("dbAjoutAllergen.errorWhenAddingNewAllergen"), error);
      })
      .finally(() => {
        setIsAddingAllergenLoading(false);
      });
  };
  return (
    <div className="flex lg:px-12 justify-center items-center flex-col pt-6 w-full">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600">
        {t("dbAjoutAllergen.pageTitle")}
      </h1>
      <div className="border  rounded-lg p-4 w-full flex flex-col items-center justify-center gap-4 bg-white dark:bg-slate-100">
        <InputValueField
          type="text"
          placeHolder={t("dbAjoutAllergen.inputLabel")}
          stateFunction={setAllergen}
          stateValue={allergen}
        />

        <BTNSave
          submitNewData={submitNewData}
          loading={isAddingAllergenLoading}
        />
      </div>
    </div>
  );
};

export default DBAjoutAllergen;
