import React, { useState } from "react";
import { addNewSauce } from "../../../api/sauces/sauces";

import InputValueField from "../InputValueField";
import BTNSave from "../../Buttons/BTNSave";

import CustomToastContainer from "../../Notifications/CustomToastContainer";
import { useTranslation } from "react-i18next";
const DBNewSauce = () => {
  const [isToastVisible, setIsToastVisible] = useState(true);
  const [sauce, setSauce] = useState("");
  const { notifyToast } = CustomToastContainer();
  const { t } = useTranslation();
  const submitNewData = () => {
    if (!sauce.trim()) {
      if (isToastVisible) {
        notifyToast(
          "error",
          t("dbNewSauce.errorEmptySauceName"),
          "idErrorEntrerNomSauce6156154574"
        );

        setIsToastVisible(false);
        setTimeout(() => setIsToastVisible(true), 5000);
      }
      return;
    }

    const data = {
      sauce_name: sauce,
    };
    addNewSauce(data)
      .then((res) => {
        notifyToast(
          "success",
          `${t("dbNewSauce.newSauce")} "${data.sauce_name}" ${t(
            "dbNewSauce.added"
          )}`,
          "idNewSauceAdd6453634"
        );
        setSauce("");
      })
      .catch((error) => {
        console.error(t("dbNewSauce.errorWhenAddingNewSauce"), error);
      });
  };
  return (
    <div className="flex lg:px-12 justify-center items-center flex-col pt-6 w-full">
      <h1 className="text-3xl font-bold mb-6 dark:text-red-600">
        {t("dbNewSauce.addASauce")}
      </h1>
      <div className="border  rounded-lg p-4 w-full flex flex-col items-center justify-center gap-4 bg-white dark:bg-slate-100">
        <InputValueField
          type="text"
          placeHolder={t("dbNewSauce.sauceName")}
          stateFunction={setSauce}
          stateValue={sauce}
        />

        <BTNSave submitNewData={submitNewData} />
      </div>
    </div>
  );
};

export default DBNewSauce;
