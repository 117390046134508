import React from "react";
import { Route, Routes } from "react-router-dom";
import UserProfile from "../components/User/UserProfile";
import UserOrders from "../components/User/UserOrders";
import { Header } from "../components";

const User = ({ isAdmin }) => {
  return (
    <div
      className={`w-screen min-h-screen flex items-center justify-start flex-col bg-primary dark:bg-primary-dark `}
    >
      <Header isAdmin={isAdmin} />

      <Routes>
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/orders" element={<UserOrders />} />
      </Routes>
    </div>
  );
};

export default User;
