import React, { useState } from "react";
import { motion } from "framer-motion";
import { buttonClick } from "../animations";

const Footer = () => {
  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };

  const handleHoverEnd = () => {
    setIsHovering(false);
  };

  return (
    <footer className="w-full text-center py-4 bg-red-500">
      <motion.div {...buttonClick} whileHover={{ scale: 1.15 }}>
        <p>
          <a
            href="https://www.gregorydemeur.be"
            className="text-black transition-transform duration-200 hover:text-white"
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverEnd}
          >
            Made with{" "}
            {isHovering ? (
              <span className="text-white">🤍</span>
            ) : (
              <span className="text-red-500">❤️</span>
            )}
            &nbsp;by GDM
          </a>
        </p>
      </motion.div>
    </footer>
  );
};

export default Footer;
