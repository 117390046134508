import React, { useEffect, useState } from "react";

import { getAllAllergens } from "../../../api/allergens/allergens";
import { useTranslation } from "react-i18next";
const DBAllergensList = ({ setAllergen, selectedAllergens }) => {
  const [allergens, setAllergens] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchAllergens = async () => {
      try {
        const allergensFromDB = await getAllAllergens();
        setAllergens(allergensFromDB);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des allergènes : ",
          error
        );
      }
    };

    fetchAllergens();
  }, []);

  const handleAllergenClick = (allergenId) => {
    setAllergen((prevAllergen) => {
      if (prevAllergen.includes(allergenId)) {
        return prevAllergen.filter((id) => id !== allergenId);
      } else {
        return [...prevAllergen, allergenId];
      }
    });
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">
        {t("DBAllergensList.selectAllergens")}
      </h2>

      <div className="flex items-center justify-around gap-3 flex-wrap">
        {allergens &&
          allergens.map((data) => (
            <p
              key={data.allergen_id}
              onClick={() => handleAllergenClick(data.allergen_id)}
              className={`px-4 py-3 rounded-md text-xl font-semibold cursor-pointer hover:shadow-md border border-gray-200 backdrop-blur-md ${
                selectedAllergens.includes(data.allergen_id)
                  ? "bg-red-400 text-primary dark:text-primary-dark"
                  : "bg-transparent"
              }`}
            >
              {data.allergen_name}
            </p>
          ))}
      </div>
    </div>
  );
};

export default DBAllergensList;
