import React from "react";
import { FaListUl } from "react-icons/fa";
import { MdOutlineGridView } from "react-icons/md";

const DBStatsViewToggle = ({ viewType, setViewType }) => {
  return (
    <div className="flex justify-end p-2 mb-4">
      <button
        onClick={() => setViewType("grid")}
        className={`mr-2 text-2xl ${
          viewType === "grid"
            ? "text-red-500 font-semibold "
            : "text-black dark:text-slate-200"
        }`}
      >
        <MdOutlineGridView />
      </button>
      <button
        onClick={() => setViewType("list")}
        className={`text-2xl ${
          viewType === "list"
            ? "text-red-500 font-semibold"
            : "text-black dark:text-slate-200"
        }`}
      >
        <FaListUl />
      </button>
    </div>
  );
};

export default DBStatsViewToggle;
