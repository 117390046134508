import React from "react";

import { Route, Routes } from "react-router-dom";

import {
  DBNewFood,
  DBHome,
  DBHeader,
  DBNewSupplement,
  DBSupplements,
} from "./";
import DBOrders from "./DBOrders/DBOrders";
import DBAjoutCategory from "./DBAjoutCategory/DBAjoutCategory";
import DBNewSauce from "./DBAjoutSauce/DBNewSauce";
import DBSettings from "./DBSettings/DBSettings";
import DBAjoutAllergen from "./DBAjoutAllergens/DBAjoutAllergen";
import DBFood from "./DBFood/DBFood";
import DBAllergens from "./DBAllergens/DBAllergens";
import DBCategories from "./DBCategories/DBCategories";
import DBSauces from "./DBSauces/DBSauces";
import DBClients from "./DBClients/DBClients";

const DBRightSection = () => {
  return (
    <div className="flex flex-col py-12 lg:px-12 px-3 flex-1 h-full ">
      <DBHeader />
      <div className="flex flex-col flex-1 overflow-y-scroll scrollbar-none">
        <Routes>
          <Route path="/home" element={<DBHome />} />
          <Route path="/orders" element={<DBOrders />} />
          <Route path="/food" element={<DBFood />} />
          <Route path="/categories" element={<DBCategories />} />
          <Route path="/sauces" element={<DBSauces />} />
          <Route path="/newFood" element={<DBNewFood />} />
          <Route path="/newSupplement" element={<DBNewSupplement />} />
          <Route path="/clients" element={<DBClients />} />
          <Route path="/supplements" element={<DBSupplements />} />
          <Route path="/allergens" element={<DBAllergens />} />
          <Route path="/newCategory" element={<DBAjoutCategory />} />
          <Route path="/newAllergen" element={<DBAjoutAllergen />} />
          <Route path="/newSauce" element={<DBNewSauce />} />
          <Route path="/settings" element={<DBSettings />} />
        </Routes>
      </div>
    </div>
  );
};

export default DBRightSection;
