import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DBOffDaysCalendar.css';
import moment from 'moment';
const DBOffDaysCalendar = ({ offDays, setOffDays }) => {



  // Function for parsing a date string to a Date object
  const parseDateString = (dateString) => {
    return dateString ? moment(dateString).toDate() : null;
  };
  

  // Function for checking if a date is an offDay
  const isOffDay = (date) => {
    return offDays.some(offDay => new Date(offDay).toDateString() === date.toDateString());
  };

 // Function for handling a day click
const handleDayClick = (date) => {
    const dateString = moment(date).format('YYYY-MM-DD'); // format local sans conversion en UTC
    if (isOffDay(date)) {
      // Delete this date from offDays
      setOffDays(offDays.filter(d => d !== dateString));
      console.log("date à supprimer = " + dateString);
    } else {
      // Add this date to offDays
      if (!offDays.includes(dateString)) {
        setOffDays([...offDays, dateString]);
        console.log("date à ajouter = " + dateString);
      }
    }
  };

  // Custom day class name for highlighting offDays
  const highlightWithClassName = (date) => {
    return isOffDay(date) ? "off-day-highlight" : undefined;
  };

  return (
    <DatePicker
    inline
    selected={null}
    onSelect={() => {}} // Hack to prevent the calendar from closing when selecting a date.
    onChange={handleDayClick}
    dayClassName={highlightWithClassName}
    highlightDates={offDays.map(parseDateString)}
  />
  );
};

export default DBOffDaysCalendar;
