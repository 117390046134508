import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaUtensils,
  FaCalendarAlt,
  FaTag,
  FaMoneyBillWave,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import QRCode from "react-qr-code";
import Modal from "react-responsive-modal";

const DBOrderCard = ({ order, updateOrderStatus, deleteOrder }) => {
  const [status, setStatus] = useState(order.statut);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    updateOrderStatus(order.orderNumber, newStatus);
  };
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsModalOpen(false);
    deleteOrder(order.orderNumber);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBorderStatus = () => {
    switch (status) {
      case "En attente":
        return "border-yellow-500";
      case "En cours":
        return "border-blue-500";
      case "Payée":
        return "border-green-500";
      case "Terminée":
        return "border-gray-500";
      default:
        return "border-yellow-500";
    }
  };
  return (
    <div
      className={`p-4 shadow-lg rounded-lg bg-white dark:bg-slate-100 border-l-4  relative ${handleBorderStatus()}`}
    >
      <div className="absolute bottom-4 right-4">
        <FaTrash
          className="cursor-pointer text-red-600 text-xl"
          onClick={handleDeleteClick}
        />
      </div>
      <div className="flex justify-between items-center">
        <h3 className="font-bold text-lg ">{order.userName}</h3>
        <div className="w-24 h-24">
          <QRCode value={order.orderNumber} size={96} />
        </div>
      </div>
      <p className="text-sm text-gray-600 flex items-center mt-2">
        <FaCalendarAlt className="mr-2" />
        {new Date(order.date).toLocaleString()}
      </p>
      <p className="text-sm flex items-center mt-2">
        <FaUtensils className="mr-2" />
        {t("DBOrderCard.sauce")} {order.sauce}
      </p>
      <div className="text-sm flex items-center mt-2">
        <FaTag className="mr-2" />
        {t("DBOrderCard.status")}
        <select
          className="ml-2 border border-gray-300 rounded p-1"
          value={status}
          onChange={(e) => handleStatusChange(e.target.value)}
        >
          <option value="En attente">{t("DBOrderCard.pending")}</option>
          <option value="En cours">{t("DBOrderCard.inProgress")}</option>
          <option value="Payée">{t("DBOrderCard.paid")}</option>
          <option value="Terminée">{t("DBOrderCard.completed")}</option>
        </select>
      </div>
      <p className="font-bold flex items-center mt-2">
        <FaMoneyBillWave className="mr-2" />
        {t("DBOrderCard.total")} {order.orderTotal} €
      </p>
      <div className="mt-4">
        <h4 className="font-bold">Bento</h4>
        <ul className="list-disc list-inside">
          {order.items.map((item, itemIndex) => (
            <li key={item.food_id + "-" + itemIndex} className="text-sm">
              {item.food_quantity} x {item.food_name} -{" "}
              {item.food_price / item.food_quantity} €/unité
              {item.food_supplements && item.food_supplements.length > 0 && (
                <ul className="list-disc list-inside ml-4">
                  {item.food_supplements.map((supplement, index) => (
                    <li key={index} className="text-xs flex items-center">
                      <FaPlus className="text-xs mr-1 text-green-500" />
                      {supplement.supplement_name}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        center
        classNames={{ modal: "customModal" }}
      >
        <h2 className="text-2xl font-bold  mb-4">
          {t("DBOrderCard.deleteOrder")}
        </h2>
        <p>{t("DBOrderCard.deleteOrderConfirm")}</p>
        <div className="flex justify-around mt-4">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
            onClick={handleDeleteConfirm}
          >
            {t("DBOrderCard.delete")}
          </button>
          <button
            className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 transition duration-300"
            onClick={handleModalClose}
          >
            Annuler
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DBOrderCard;
